import type { SVGProps } from 'react';

export const ChevronIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width='14'
      height='8'
      viewBox='0 0 14 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.37451 1.08331L7.20785 6.91665L13.0412 1.08331'
        stroke='#494949'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
