import theme from '@styles/theme';

export const AttachmentIcon = ({
  width = 12,
  height = 14,
  fill = theme.palette.greyscale[6],
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.25269 4.71567L3.59535 8.373C3.04469 8.92367 3.04469 9.817 3.59535 10.3677V10.3677C4.14602 10.9183 5.03935 10.9183 5.59002 10.3677L10.4114 5.54633C11.4214 4.53633 11.4214 2.899 10.4114 1.889V1.889C9.40135 0.879 7.76402 0.879 6.75402 1.889L1.93269 6.71033C0.463355 8.17967 0.463355 10.561 1.93269 12.0303V12.0303C3.40202 13.4997 5.78336 13.4997 7.25269 12.0303L10.1787 9.10433'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
