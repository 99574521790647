import { ReactElement } from 'react';
import { Input as AntInput, InputProps } from 'antd';
import styled from 'styled-components';

import { Label } from '../Label';

interface IProps extends InputProps {
  label?: string;
  labelStyle?: React.CSSProperties;
  requiredSymbol?: boolean;
  valid?: boolean;
}

const Input = (props: IProps): ReactElement => {
  const { label, labelStyle, type, requiredSymbol, ...res } = props;

  return (
    <>
      {label && (
        <>
          <Label
            label={label}
            requiredSymbol={requiredSymbol}
            style={labelStyle}
          />
          <br />
        </>
      )}
      <StyledInput type={type} {...res} />
    </>
  );
};

const StyledInput = styled(AntInput)<IProps>`
  &.ant-input {
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '35px')};
    padding: 10px 8px;
    background: ${(props) => props.theme.palette.greyscale[0]};
    font-size: ${(props) => props.theme.palette.textFontSize[7]};
    line-height: ${(props) => props.theme.palette.textLineHeight[5]};
    border: 1px solid
      ${({ theme, required, valid, value }) =>
        valid === false || (required && !value)
          ? theme.palette.color[0]
          : theme.palette.greyscale[3]};
    border-radius: 4px;

    &.ant-input-disabled,
    &.ant-input-disabled:hover {
      background: ${(props) => props.theme.palette.greyscale[0]};
      border-color: ${(props) => props.theme.palette.greyscale[3]};
      color: ${(props) => props.theme.palette.text[0]};
    }

    &::placeholder {
      color: ${(props) => props.theme.palette.text[3]};
    }

    &:hover {
      border-color: ${(props) => props.theme.palette.primary[0]};
    }

    &:focus,
    &:active {
      border-color: ${(props) => props.theme.palette.primary[0]};
      box-shadow: ${(props) => props.theme.palette.shadow[1]};
    }
  }
`;

export { Input };
