import type { HubCapsule } from '@aws-amplify/core';

// need the Hub Listener to detect auth events to trigger rerenders
export const AuthListener = async (
  data: HubCapsule,
  setupSession: (routerPath?: string) => Promise<void>,
  setSessionLoading: (loading: boolean) => void,
  takedownSession: () => Promise<void>
): Promise<void> => {
  setSessionLoading(true);
  switch (data.payload.event) {
    case 'signIn':
      console.log('user signed in');
      await setupSession();
      break;
    case 'signUp':
      console.log('user signed up');
      break;
    case 'signOut':
      console.log('user signed out');
      await takedownSession();
      break;
    case 'signIn_failure':
      console.log('user sign in failed');
      break;
    case 'configured':
      console.log('the Auth module is configured');
      break;
    case 'autoSignIn':
      console.log('auto sign in', data.payload.data);
      break;
    case 'autoSignIn_failure':
      console.log('auto sign in failed');
      break;
    case 'confirmSignUp':
      console.log('user sign up confirmed');
      break;
    case 'forgotPassword':
      console.log('password recovery initiated');
      break;
    case 'forgotPassword_failure':
      console.log('password recovery failed');
      break;
    case 'forgotPasswordSubmit':
      console.log('password confirmation successful');
      break;
    case 'forgotPasswordSubmit_failure':
      console.log('password confirmation failed');
      break;
    case 'customOAuthState':
      console.log('customOAuthState', data.payload);
      await setupSession(data?.payload?.data);
      break;
    default:
      console.log('auth event not handled: ', data?.payload?.event);
      console.debug('full data: ', data);
  }
  setSessionLoading(false);
};
