export const googleSignout = (): void => {
  const signoutUrl =
    'https://' +
    process.env.NEXT_PUBLIC_COGNITO_DOMAIN +
    '/logout?client_id=' +
    process.env.NEXT_PUBLIC_CLIENT_ID +
    '&logout_uri=' +
    process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGN_OUT;
  try {
    fetch(signoutUrl);
  } catch (err) {
    console.log(err);
  }
};
