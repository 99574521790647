import axios from 'axios';

import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';
import {
  EverysetUser,
  IUserAccount,
  IUserDeletePhoto,
  IUserSkill,
  IVehicleData,
  UserNotificationPreference,
} from '@lib/types/user.types';

export class UsersCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Users;
  private static apiNameVehicle = ApiNames.DeleteVehicle;
  private static apiDeletePhotos = ApiNames.DeletePhotos;

  public static updateUserBasicInfo(
    userId: number,
    newValues: UpdateUserBasicInfo
  ): Promise<EverysetUser> {
    return UsersCommandApi._updateUserInfo(userId, newValues);
  }

  public static updateUserUnionInfo(
    userId: number,
    newValues: UpdateUserUnion
  ): Promise<EverysetUser> {
    return UsersCommandApi._updateUserInfo(userId, newValues);
  }

  public static updateUserInfo(
    userId: number,
    newValues: UpdateUserInfo
  ): Promise<EverysetUser> {
    return UsersCommandApi._updateUserInfo(userId, newValues);
  }
  public static updateUserGeneralInfo(
    userId: number,
    newValues: UpdateUserGeneralInfo
  ): Promise<EverysetUser> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}`, HttpMethod.PUT, newValues)
    );
  }

  public static updateUserNotificationPreferenceAll(
    userId: number,
    areAllNotificationsEnabled: boolean
  ): Promise<UserNotificationPreference[]> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/notification_preferences`,
        HttpMethod.PUT,
        [
          {
            notification_type: 'all',
            opt_in: areAllNotificationsEnabled,
          },
        ]
      )
    );
  }

  public static createUserAppearance(
    userId: number,
    data: CreateOrUpdateUserAppearance
  ): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/appearance`,
        HttpMethod.POST,
        data
      )
    );
  }

  public static updateUserAppearance(
    userId: number,
    data: CreateOrUpdateUserAppearance
  ): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/appearance`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static updateUserAppearanceInfo(
    userId: number,
    data: UserAppearance
  ): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/appearance`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static updateUserAppearanceCall(
    userId: number,
    data: CreateOrUpdateUserAppearanceCall
  ): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/appearance`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static createUserSkill(
    userId: number,
    data: CreateUserSkillRequest
  ): Promise<IUserSkill> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/skills?is_new_entity=true`,
        HttpMethod.POST,
        data
      )
    );
  }

  public static updateUserSkill(
    skillId: number,
    newData: UpdateUserSkill
  ): Promise<UserSkillNew> {
    const endpoint = `/${skillId}/skills`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, newData)
    );
  }

  public static deleteUserSkill(skillIds: number[]): Promise<IUserSkill> {
    const newSkillsString = skillIds.join(',');
    const endpoint = `/skills?newSkillIds=[${newSkillsString}]`;

    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }
  public static createUserProps(
    userId: number,
    data: CreateUserSkillRequest
  ): Promise<IUserSkill> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/props`, HttpMethod.POST, data)
    );
  }
  public static deleteUserProps(propId: number[]): Promise<IUserSkill> {
    const propIdsString = propId.join(',');
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/props?propIds=[${propIdsString}]`,
        HttpMethod.DELETE
      )
    );
  }

  private static _updateUserInfo(
    userId: number,
    newValues: UpdateUserInfo
  ): Promise<EverysetUser> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}`, HttpMethod.PUT, newValues)
    );
  }

  public static createUserVehicles(
    userId: number,
    data: vehiclesType
  ): Promise<IVehicleData> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/vehicles`, HttpMethod.POST, data)
    );
  }

  public static deleteUserVehicles(ids: number): Promise<IVehicleData> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiNameVehicle,
        `/vehicles?ids=[${ids}]`,
        HttpMethod.DELETE
      )
    );
  }

  public static deleteUserPhotos(
    user_Photo_id: number
  ): Promise<IUserDeletePhoto> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiDeletePhotos,
        `/${user_Photo_id}`,
        HttpMethod.DELETE
      )
    );
  }

  public static updateUserVehicles(
    vehicle_id: number,
    data: vehiclesType
  ): Promise<IVehicleData> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiNameVehicle,
        `vehicles/${vehicle_id}`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static userDisable(
    userId: number,
    data: userAccount
  ): Promise<IUserAccount> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${userId}/disableAccount`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static async sendEmail(
    email: string,
    description: string,
    clientHost: string
  ) {
    const endpoint = `${process.env.NEXT_PUBLIC_API_BASE_PATH}/usrs/public/sendEmail`;
    try {
      return await axios.post(endpoint, {
        email,
        description,
        clientHost,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
