import axios from 'axios';

import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';
export class PublicApplicationAPI {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.PublicApplications;
  private static apiSubmit = ApiNames.SubmitApplication;

  public static async getPublicApplication(
    ApplicationId: string
  ): Promise<PublicApplication> {
    console.log('ApplicationId', ApplicationId);

    const endpoint = `${process.env.NEXT_PUBLIC_API_BASE_PATH}/cast_applications/applications/public/${ApplicationId}`;
    try {
      const response = await axios.get(endpoint);
      const data: PublicApplication = response.data;
      console.log('data', data);
      return data;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Propagate the error if needed
    }
  }

  public static SubmitApplication(
    data: PostSubmitApplicationRequest
  ): Promise<SumbitApplicationResponse> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiSubmit, `/application`, HttpMethod.POST, data)
    );
  }
}
