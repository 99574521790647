const URL = process.env.NEXT_PUBLIC_API_BASE_PATH;

const buildEndpoint = (subApi: string) => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === 'local') return URL;
  return `${URL}/${subApi}`;
};

export enum ApiNames {
  Photos = 'PhotosApi',
  Users = 'UsersApi',
  Locations = 'LocationsApi',
  Bookings = 'BookingsApi',
  Invites = 'InvitesApi',
  FirstAvail = 'FirstAvail',
  listMemberships = 'listMemberships',
  InvitedAction = 'InvitedActionApi',
  FirstAvailAction = 'FirstAvailActionApi',
  BookedAction = 'BookedActionApi',
  bookings = 'bookings',
  Vehicles = 'Vehicles',
  CarMake = 'CarMake',
  DeleteVehicle = 'DeleteVehicle',
  DisableAccount = 'DisableAccount',
  ArchivedInvites = 'ArchivedInvites',
  ArchivedBookings = 'ArchivedBookings',
  ArchivedFirstAvail = 'ArchivedFirstAvail',
  SubmitApplication = 'SubmitApplication',
  DeletePhotos = 'DeletePhotos',
  CustomQuestions = 'CustomQuestions',
  PublicApplications = 'PublicApplications',
}

export const awsApiConfig = {
  endpoints: [
    {
      name: ApiNames.Photos,
      endpoint: buildEndpoint('phot/photos'),
    },
    {
      name: ApiNames.Users,
      endpoint: buildEndpoint('usrs/users'),
    },
    {
      name: ApiNames.Locations,
      endpoint: buildEndpoint('loc/locations'),
    },
    {
      name: ApiNames.Bookings,
      endpoint: buildEndpoint('cast_jobs/bookings'),
    },
    {
      name: ApiNames.Invites,
      endpoint: buildEndpoint('cast_jobs/submissions'),
    },
    {
      name: ApiNames.FirstAvail,
      endpoint: buildEndpoint('cast_jobs/firstAvail'),
    },
    {
      name: ApiNames.listMemberships,
      endpoint: buildEndpoint('cast_jobs/listMemberships'),
    },
    {
      name: ApiNames.InvitedAction,
      endpoint: buildEndpoint('cast_jobs/actionsRequired'),
    },
    {
      name: ApiNames.FirstAvailAction,
      endpoint: buildEndpoint('cast_jobs/actionsRequired'),
    },
    {
      name: ApiNames.BookedAction,
      endpoint: buildEndpoint('cast_jobs/actionsRequired'),
    },
    {
      name: ApiNames.Vehicles,
      endpoint: buildEndpoint('cast_jobs/'),
    },
    {
      name: ApiNames.CarMake,
      endpoint: buildEndpoint('integrations'),
    },
    {
      name: ApiNames.DeleteVehicle,
      endpoint: buildEndpoint('usrs/'),
    },
    {
      name: ApiNames.DisableAccount,
      endpoint: buildEndpoint('usrs/'),
    },
    {
      name: ApiNames.ArchivedInvites,
      endpoint: buildEndpoint('cast_jobs/archived'),
    },
    {
      name: ApiNames.ArchivedBookings,
      endpoint: buildEndpoint('cast_jobs/archived'),
    },
    {
      name: ApiNames.ArchivedFirstAvail,
      endpoint: buildEndpoint('cast_jobs/archived'),
    },
    {
      name: ApiNames.SubmitApplication,
      endpoint: buildEndpoint('cast_jobs/submit'),
    },
    {
      name: ApiNames.DeletePhotos,
      endpoint: buildEndpoint('uphot/userPhotos'),
    },
    {
      name: ApiNames.CustomQuestions,
      endpoint: buildEndpoint('cast_jobs/questions'),
    },
    {
      name: ApiNames.PublicApplications,
      endpoint: buildEndpoint('cast_applications/applications'),
    },
  ],
};
