import { EverysetUser } from '@lib/types/user.types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  user: {
    profile?: EverysetUser;
    IUserInfoDAO?: UserProfile;
    UserAppearance?: UserAppearance;
    InvitesActionType?: InvitesActionTypeDTO;
    FirstAvailActionType?: FirstAvailActionTypeDTO;
    BookedActionType?: BookingsActionTypeDTO;
    allUserIds?: any; // Add the allUserIds field here
    onboardingFirstLoad?: boolean;
    notificationPreferences?: Record<string, boolean>;
  };
}

const initialState: AppState = {
  user: {
    profile: null,
    IUserInfoDAO: null,
    UserAppearance: null,
    InvitesActionType: null,
    FirstAvailActionType: null,
    BookedActionType: null,
    allUserIds: null,
    onboardingFirstLoad: true,
    notificationPreferences: null,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<EverysetUser>) => {
      state.user.profile = action.payload;
    },
    setUserProfileInfo: (state, action: PayloadAction<UserProfile>) => {
      state.user.IUserInfoDAO = action.payload;
    },
    setUserAppearance: (state, action: PayloadAction<UserAppearance>) => {
      state.user.UserAppearance = action.payload;
    },
    setInvitesActionType: (
      state,
      action: PayloadAction<InvitesActionTypeDTO>
    ) => {
      state.user.InvitesActionType = action.payload;
    },
    setFirstAvailActionType: (
      state,
      action: PayloadAction<FirstAvailActionTypeDTO>
    ) => {
      state.user.FirstAvailActionType = action.payload;
    },
    setBookedActionType: (
      state,
      action: PayloadAction<BookingsActionTypeDTO>
    ) => {
      state.user.BookedActionType = action.payload;
    },
    setAllUserIds: (state, action: PayloadAction<any>) => {
      state.user.allUserIds = action.payload;
    },
    setOnboardingFirstLoad: (state, action) => {
      state.user.onboardingFirstLoad = action.payload;
    },
    setNotificationPreferences: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.user.notificationPreferences = action.payload;
    },
    setNotificationPreference: (
      state,
      action: PayloadAction<{ notificationType: string; optIn: boolean }>
    ) => {
      const { notificationType, optIn } = action.payload;
      state.user.notificationPreferences = {
        ...(state.user.notificationPreferences ?? {}),
        [notificationType]: optIn,
      };
    },
    resetStore: () => {
      // Reset the state to its initial value
      return initialState;
    },
  },
});

export const {
  setUserProfile,
  setUserProfileInfo,
  setUserAppearance,
  setInvitesActionType,
  setFirstAvailActionType,
  setBookedActionType,
  setAllUserIds,
  resetStore,
  setOnboardingFirstLoad,
  setNotificationPreferences,
  setNotificationPreference,
} = appSlice.actions;

export default appSlice.reducer;
