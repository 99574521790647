export function orderBasedOnConstArr<T>(
  constArr: T[] | readonly T[],
  toOrder: T[]
): T[] {
  return [...toOrder].sort((a, b) => {
    return constArr.indexOf(a) - constArr.indexOf(b);
  });
}

export function arraysAreEqual<T>(
  array1: T[] | readonly T[],
  array2: T[]
): boolean {
  if (array1?.length !== array2?.length) {
    return false;
  }
  return array1?.every((element, index) => element === array2?.[index]);
}

export function unsortedArraysAreEqual<T>(
  array1: T[] | readonly T[],
  array2: T[]
): boolean {
  const orderedArray2 = orderBasedOnConstArr(array1, array2);
  return arraysAreEqual(array1, orderedArray2);
}
