import { ApiNames, ApiProxy, ApiRequest } from '@lib/api/apiProxy';

export class FirstAvailQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.FirstAvail;

  public static getUserFirstAvail(
    userId: string | number
  ): Promise<FirstAvailDTO[]> {
    const endpoint = `/user/${userId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}

export class FirstAvailActionQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.FirstAvailAction;
  private static apiNameArchived = ApiNames.ArchivedFirstAvail;

  public static getUserFirstAvailAction(
    userId: string | number
  ): Promise<SubmissionDetails[]> {
    const endpoint = `/user/${userId}?response_type=data&action_type=first_avail`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
  public static getUserFirstAvailArchived(
    userId: string | number
  ): Promise<SubmissionDetails[]> {
    const endpoint = `/user/${userId}?archived_type=firstAvail`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiNameArchived, endpoint)
    );
  }

  public static getUserFirstAvailActionType(
    userId: string | number
  ): Promise<FirstAvailActionTypeDTO> {
    const endpoint = `/user/${userId}?action_type=first_avail`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
