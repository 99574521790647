import { ApiNames, ApiProxy, ApiRequest } from '@lib/api/apiProxy';

export class PhotosQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Photos;

  public static getUploadUrl(
    fileName: string,
    fileType?: string,
    bucket?: BucketType
  ): Promise<UploadUrl> {
    let endpoint = `/requestSignedUrl?fileName=${fileName}`;
    if (bucket) {
      endpoint += `&bucket=${bucket}`;
    }
    if (fileType) {
      endpoint += '&s3Fields=' + JSON.stringify({ 'Content-Type': fileType });
    }
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getUserPhotos(userId: number): Promise<UserPhoto[]> {
    const endpoint = `/user/${userId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
