import { createContext, useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@lib/redux.lib/store';

type Props = {
  children: ReactNode;
};

type ContextValue = {
  onboardingLoading: boolean;
  setOnboardingLoading: (value: boolean) => void;
  addBrowseAwayListener: () => void;
  removeBrowseAwayListener: () => void;
};

export const OnboardingStateContext = createContext({} as ContextValue);

export const OnboardingStateContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [onboardingLoading, setOnboardingLoading] = useState(false);
  const router = useRouter();
  const isOnboarded = useSelector(
    (state: RootState) => state.app.user.profile?.bg_onboarded
  );

  const handleBrowseAway = useCallback(() => {
    if (!isOnboarded) {
      router.events.emit('routeChangeError');
      throw 'routeChange aborted.';
    }
  }, [router, isOnboarded]);

  const addBrowseAwayListener = useCallback(
    () => router.events.on('routeChangeStart', handleBrowseAway),
    [router, handleBrowseAway]
  );

  const removeBrowseAwayListener = useCallback(
    () => router.events.off('routeChangeStart', handleBrowseAway),
    [router, handleBrowseAway]
  );

  return (
    <OnboardingStateContext.Provider
      value={{
        onboardingLoading,
        setOnboardingLoading,
        addBrowseAwayListener,
        removeBrowseAwayListener,
      }}
    >
      {children}
    </OnboardingStateContext.Provider>
  );
};

export const useOnboardingStateContext: () => ContextValue = () =>
  useContext<ContextValue>(OnboardingStateContext);
