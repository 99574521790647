const Close = ({
  stroke = '#E14A4A',
  className = '',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' close-icon'}
    {...otherProps}
  >
    <path
      d='M4 4L12 12'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 4L4 12'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Close;
