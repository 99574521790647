import { ReactElement } from 'react';
import { Input, InputProps } from 'antd';
import styled from 'styled-components';

import { Label } from '../Label';

interface IProps extends InputProps {
  // label props
  label?: string;
  labelStyle?: React.CSSProperties;
  requiredSymbol?: boolean;

  // input props
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  value?: string;
  type?: string;
  onChange?: (event) => void;
  style?: React.CSSProperties;
}

const InputComponent = (props: IProps): ReactElement => {
  const { label, labelStyle, requiredSymbol, ...rest } = props;

  return (
    <>
      {label ? (
        <>
          <Label
            label={label}
            requiredSymbol={requiredSymbol}
            style={labelStyle}
          />
          <br />
        </>
      ) : (
        <></>
      )}
      <Input.Password {...rest} />
    </>
  );
};

const PasswordInput = styled(InputComponent)`
  &.ant-input-password {
    margin: 8px 0;
    height: 37px;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
`;

export { PasswordInput };
export default PasswordInput;
