import { Form as AntdForm } from 'antd';
import styled from 'styled-components';

const Form = styled(AntdForm)`
  .ant-input-status-error {
    border: 1px solid ${(props) => props.theme.palette.color[0]} !important; // styles Form.Item elements that contain a validator
  }

  .ant-form-item-explain-error {
    margin: 4px 0 8px 0;
    color: ${(props) => props.theme.palette.color[0]};
    font-size: ${(props) => props.theme.palette.textFontSize[7]};
  }

  .ant-input-affix-wrapper-status-error {
    border: 1px solid ${(props) => props.theme.palette.color[0]} !important; // styles Form.Item elements that do not contain a validator
  }
`;

export { Form };
