export const EverysetIcon = ({
  width = 20,
  height = 26,
  fill = '#1733C4',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        d='M9.6365 0.490906H18.9294C19.3739 0.490906 19.7294 0.846461 19.7294 1.29091V4.69293C19.7294 5.13737 19.3739 5.49293 18.9294 5.49293H8.0769L8.84458 1.15353C8.90923 0.765653 9.24862 0.490906 9.6365 0.490906Z'
        fill={fill}
      />
      <path
        d='M1.0627 5.49292H8.07684L7.19603 10.4949H1.0627C0.618251 10.4949 0.262695 10.1394 0.262695 9.69494V6.29292C0.262695 5.84848 0.618251 5.49292 1.0627 5.49292Z'
        fill={fill}
      />
      <path
        d='M7.19599 10.4949H18.9293C19.3738 10.4949 19.7293 10.8505 19.7293 11.2949V14.697C19.7293 15.1414 19.3738 15.497 18.9293 15.497H6.31519L7.19599 10.4949Z'
        fill={fill}
      />
      <path
        d='M1.07088 15.505H6.31533L5.43452 20.5071H1.07088C0.62644 20.5071 0.270884 20.1515 0.270884 19.7071V16.305C0.262803 15.8606 0.62644 15.505 1.07088 15.505Z'
        fill={fill}
      />
      <path
        d='M5.43436 20.5071H18.9374C19.3818 20.5071 19.7374 20.8626 19.7374 21.3071V24.7091C19.7374 25.1535 19.3818 25.5091 18.9374 25.5091H5.50708C5.00607 25.5091 4.63436 25.0566 4.71517 24.5717L5.43436 20.5071Z'
        fill={fill}
      />
    </svg>
  );
};
