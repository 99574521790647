import { createContext, ReactNode, useContext, useEffect } from 'react';

import { useImpersonateUser } from '@hooks/impersonation/useImpersonateUser';
import { AuthUserSession } from '@lib/types/authUser.types';
import { EverysetUser } from '@lib/types/user.types';

import { useOnboardingStatus } from './useOnboardingStatus';
import { useSession } from './useSession';

interface IAuthContext {
  token: string;
  isAuthenticated: boolean;
  authUserSession: AuthUserSession;
  sessionLoading?: boolean;
  setSessionLoading?: (loading: boolean) => void;
  logout?: () => void;
  processImpersonatedUserId: () => void;
  getImpersonatedUser: () => Promise<EverysetUser>;
}

const AuthContext = createContext({} as IAuthContext);

type Props = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const { authUserSession, sessionLoading, setSessionLoading, logout } =
    useSession();
  const { processImpersonatedUserId, getImpersonatedUser } =
    useImpersonateUser(authUserSession);

  useEffect(() => {
    processImpersonatedUserId();
  }, [processImpersonatedUserId]);

  useOnboardingStatus(authUserSession, logout);

  return (
    <AuthContext.Provider
      value={{
        token: null,
        isAuthenticated: !!authUserSession,
        authUserSession,
        sessionLoading,
        setSessionLoading,
        logout,
        processImpersonatedUserId,
        getImpersonatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// custom hook so we only have to import useContext in this component
export const useAuth: () => IAuthContext = () =>
  useContext<IAuthContext>(AuthContext);
