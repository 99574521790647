export const CARRIER = [
  'Carrier',
  'Baby Stroller',
  'Backpack',
  'Briefcase',
  'Luggage',
] as const;

export const MUSIC = [
  'Music',
  'Bongos',
  'Instrument - Guitar',
  'Instrument - Keyboard',
  'Instrument - Marching Band',
  'Instrument - Orchestra',
] as const;

export const TRANSPORTATION = [
  'Transportation',
  'Bicycle - City',
  'Bicycle - Cruiser',
  'Bicycle - Mountain',
  'Bicycle - Other',
  'Moped Scooter',
  'Scooter',
  'Unicycle',
] as const;

export const SPORT = [
  'Sport',
  'Baseball Glove',
  'Boxing Gloves',
  'Frisbee',
  'Golf Clubs',
  'Ice Skates',
  'Longboard',
  'Pointe Shoes',
  'Roller Blades',
  'Roller Skates',
  'Skateboard',
  'Skiis',
  'Snowboard',
  'Surfboard',
  'Tennis Racket',
  'Wetsuit',
  'Yoga Mat',
] as const;

export const OTHER = [
  'Other',
  'Camera',
  'DJ Equipment',
  'Dog',
  'Laptop',
  'Stenographer Machines',
  'Tactical Boots',
] as const;
