import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button, Divider, Modal } from 'antd';
import LoginIndex from 'pages/auth/login';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';
import Header from '@layouts/components/Header/Header';
import { PublicApplicationAPI } from '@lib/api/endpoints/Applications';
import { RootState } from '@lib/redux.lib/store';

import ProjectDetails from '../ProjectDetails';

export const FORM_WIDTH = 899;

const getApplicationIdFromUrl = (url: string) => {
  if (!url) return;
  const searchString = 'apps/';
  const index = url.indexOf(searchString);
  const applicationId = url.substring(index + searchString.length);

  return applicationId.split(/\?|\//gi)[0];
};

const PublicApplicationIndex = (): JSX.Element => {
  const [data, setData] = useState<PublicApplication>();
  const [loginModal, setLoginModal] = useState(false);
  const userId = useSelector(
    (state: RootState) => state.app?.user?.profile?.id
  );
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const url = router?.asPath;
  const applicationId = getApplicationIdFromUrl(url);

  useEffect(() => {
    const getApplicationData = async () => {
      try {
        const applicationData = await PublicApplicationAPI.getPublicApplication(
          applicationId
        );
        setData(applicationData);
        setLoading(false);
      } catch (error) {
        console.error('error while api:', error);
        setLoading(false);
      }
    };
    getApplicationData();
  }, [applicationId]);
  const isMobileDevice = window.innerWidth <= 768;

  const handleModal = () => {
    if (userId !== undefined) {
      router.push(`/apps/${applicationId}/SubmitApplication`);
      return;
    }
    if (isMobileDevice) console.debug('MOBILE DEVICE', isMobileDevice);
    setLoginModal(true);
  };

  if (loading) {
    return <StyledLoadingOutlined spin />;
  }
  return (
    <>
      {/* Header */}
      {!isMobileDevice ? (
        <Header
          toggleCollapsed={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      ) : (
        <StyleMobileDiv>
          <StyleTitle>Now Casting</StyleTitle>
        </StyleMobileDiv>
      )}

      {/* Body */}
      <CenteredContainer style={{ paddingTop: 65 }}>
        <StyledForm>
          {Object.keys(data || {}).length && <ProjectDetails data={data} />}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ApplyNowButton onClick={() => handleModal()}>
              Apply Now
            </ApplyNowButton>
          </div>
          <div>
            <StyledText>Not interested in this role?</StyledText>
            <br />
            <Link href={'/auth/signup'}>
              <Styledtext>Sign up to join our database</Styledtext>
            </Link>

            <StyledText>
              {' '}
              to get contacted for other jobs you might be interested in
            </StyledText>
          </div>
        </StyledForm>
        <CustomModalContent
          open={loginModal}
          onCancel={() => setLoginModal(false)}
          footer={null}
        >
          <ModalHeader>Log in or sign up to apply</ModalHeader>
          <StyledDivider />
          <ModalContent>
            <LoginIndex />{' '}
          </ModalContent>
        </CustomModalContent>
      </CenteredContainer>
    </>
  );
};
export default PublicApplicationIndex;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 24px;
  color: ${(props) => props.theme.palette.primary[0]};
`;
const StyledDivider = styled(Divider)`
  margin: 0px;
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px solid ${(props) => props.theme.palette.border[1]};
  border-width: 1px 0 0 0;
`;
const StyleMobileDiv = styled.div`
  display: none;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  height: 50px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  @media (min-width: 577px) {
    display: none;
  }
`;
const StyleTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  @media (max-width: 576px) {
    margin: auto;
  }
`;
const StyledText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #202020;
`;
const Styledtext = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #1733c4;
  cursor: pointer;
`;
const ModalHeader = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 576px) {
    font-size: 16px;
    font-weight: 600;
    border: none;
    text-align: center;
  }
`;
const ModalContent = styled.div`
  width: 100%;
`;
const CustomModalContent = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }

  .css-dev-only-do-not-override-cs932n .ant-modal-content {
    padding: 0;
  }
`;
const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  @media (max-width: 576px) {
    height: 100%;
    padding: 12px;
  }
`;
const StyledForm = styled.form`
  max-width: ${FORM_WIDTH}px;
`;
const ApplyNowButton = styled(Button)`
  margin-top: 40px;
  margin-bottom: 32px;
  width: 100%;
  height: 35px;
  gap: 8px;
  background: #1733c4;
  color: #fcfcfc;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  @media (max-width: 576px) {
    width: 100%;
    margin: 24px 0;
  }
`;
