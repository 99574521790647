import { Button as AntButton } from 'antd';
import styled from 'styled-components';

const Button = styled(AntButton)`
  &.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    padding: 12px 24px;
    vertical-align: middle;
    user-select: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: ${(props) => props.theme.palette.textFontSize[8]};
    font-weight: ${(props) => props.theme.palette.textFontWeight[0]};
    line-height: 21px;

    &.ant-btn-primary {
      background: ${(props) => props.theme.palette.primary[0]};
      border: 1.5px solid ${(props) => props.theme.palette.primary[0]};
      color: white;

      &:hover {
        background: ${(props) => props.theme.palette.hover[0]};
        border-color: ${(props) => props.theme.palette.hover[0]};
      }
    }

    &.ant-btn-default {
      background: ${(props) => props.theme.palette.greyscale[0]};
      border: 1.5px solid ${(props) => props.theme.palette.border[1]};
      color: ${(props) => props.theme.palette.text[0]};

      &:hover {
        background: ${(props) => props.theme.palette.greyscale[1]};
      }
    }

    &.ant-btn-text {
      padding: 0;
      background: transparent;
      color: ${(props) => props.theme.palette.primary[0]};
      border: none;

      &:hover {
        color: ${(props) => props.theme.palette.hover[0]};
      }

      &:disabled {
        color: ${(props) => props.theme.palette.primary[13]};
      }
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      opacity: 40%;
      cursor: not-allowed;
    }
  }
`;

export { Button };
