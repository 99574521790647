import { createContext, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

type ContextValue = {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: ReactStateUpdater<boolean>;
};

const SidebarContext = createContext({} as ContextValue);

const SidebarContextProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const { asPath } = router;
  const collapseSidebarByDefault =
    asPath.includes('list_membership_id') || asPath.includes('booking_date_id')
      ? true
      : false;
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    () => collapseSidebarByDefault
  );

  return (
    <SidebarContext.Provider
      value={{ isSidebarCollapsed, setIsSidebarCollapsed }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext: () => ContextValue = () =>
  useContext<ContextValue>(SidebarContext);

export { SidebarContextProvider };
