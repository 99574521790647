export const HamburgerMenuIcon = ({
  fill = '#202020',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <path
      d='M22.5004 2.57227H1.50042C1.38256 2.57227 1.28613 2.66869 1.28613 2.78655V4.50084C1.28613 4.61869 1.38256 4.71512 1.50042 4.71512H22.5004C22.6183 4.71512 22.7147 4.61869 22.7147 4.50084V2.78655C22.7147 2.66869 22.6183 2.57227 22.5004 2.57227ZM22.5004 19.2866H1.50042C1.38256 19.2866 1.28613 19.383 1.28613 19.5008V21.2151C1.28613 21.333 1.38256 21.4294 1.50042 21.4294H22.5004C22.6183 21.4294 22.7147 21.333 22.7147 21.2151V19.5008C22.7147 19.383 22.6183 19.2866 22.5004 19.2866ZM22.5004 10.9294H1.50042C1.38256 10.9294 1.28613 11.0258 1.28613 11.1437V12.858C1.28613 12.9758 1.38256 13.0723 1.50042 13.0723H22.5004C22.6183 13.0723 22.7147 12.9758 22.7147 12.858V11.1437C22.7147 11.0258 22.6183 10.9294 22.5004 10.9294Z'
      fill={fill}
    />
  </svg>
);
