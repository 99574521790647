export const UNION_STATUSES = [
  {
    text: 'Non-Union',
    value: 'non-union',
    abbrev: 'N/U',
    otherFormats: ['NU', 'N/U', 'Non Union', 'non-union'],
  },
  { text: 'SAG-Eligible', value: 'sag-eligible', abbrev: 'SAG-E' },
  {
    text: 'SAG-AFTRA',
    value: 'sag-aftra',
    abbrev: 'SAG',
    otherFormats: ['Union', 'SAG'],
  },
  { text: 'FiCore', value: 'ficore', abbrev: 'FiC' },
  { text: 'Must Join', value: 'must-join', abbrev: 'MJ' },
];
export const UNION_STATUS_ID_LABEL = {
  'sag-aftra': 'SAG-AFTRA',
  ficore: 'Fi-Core',
};

export const SAG_STATUSES = ['sag-aftra', 'Fi-Core'];

export const CALL_IN_SERVICES = [
  { value: null, label: "No, I don't have a call in service" },
  {
    value: 'AC',
    label: 'American Casting',
  },
  {
    value: 'BT',
    label: 'Booked Talent',
  },
  {
    value: 'CUT',
    label: 'Cut Above',
  },
  {
    value: 'EM',
    label: 'Extras Management',
  },
  {
    value: 'F2F',
    label: 'Face2Face',
  },
  {
    value: 'JAL',
    label: "Jessica's A-List",
  },
  {
    value: 'JL',
    label: "Joey's List",
  },
  {
    value: 'KK',
    label: 'Kalifornia Kasting',
  },
  {
    value: 'KM',
    label: 'Kids Management',
  },
  {
    value: 'MED',
    label: 'Media Access',
  },
  {
    value: 'NA',
    label: "Nikki's Angels",
  },
  {
    value: 'OST',
    label: 'On Set Talent',
  },
  {
    value: 'PAL',
    label: 'Paloma Model & Talent',
  },
  {
    value: 'REEL',
    label: 'Reel Talent',
  },
  {
    value: 'SK',
    label: 'Studio Kids',
  },
  {
    value: 'UC',
    label: 'Uncut Casting',
  },
  {
    value: 'WUN',
    label: 'Wunder Model Mgmt',
  },
  {
    value: 'ZURI',
    label: 'Zuri Models',
  },
  {
    value: 'OS',
    label: 'Osbrink',
  },
  {
    value: 'EK',
    label: 'Elite Kids',
  },
];

export const CALL_IN_SERVICE_HELP_TEXT = `A Call-In Service is a company
that provides additional resources
and services to handle scheduling
and submissions for background
acting roles, on your behalf.`;
