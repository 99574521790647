import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { message, Popover } from 'antd';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { UsersQueryApi } from '@lib/api/endpoints';
import { useAuth } from '@lib/auth';
import { RootState } from '@lib/redux.lib/store';
import { EverysetUser } from '@lib/types/user.types';
import theme from '@styles/theme';

import GroupIcon from '../../../svg/Group.svg';

type ProfileProps = {
  impersonatedUserData: EverysetUser;
};

const Profile = ({ impersonatedUserData }: ProfileProps): JSX.Element => {
  const router = useRouter();

  const userProfile = useSelector((state: RootState) => state.app.user.profile);
  const profilePhotoUrl = userProfile?.profile_photo.url;
  const allProfileUser = useSelector(
    (state: RootState) => state.app.user?.allUserIds
  );

  const [mappedProfiles, setMappedProfiles] = useState<
    Record<number, EverysetUser | undefined>
  >({});
  const allProfileUserArray = allProfileUser
    ?.replace('[', '') // Remove opening square bracket
    ?.replace(']', '') // Remove closing square bracket
    ?.split(',')
    ?.map((userId) => parseInt(userId.trim()));
  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const profiles: Record<number, EverysetUser | undefined> = {};
        const validUserIds = allProfileUserArray.filter((userId) => userId);

        // Use Promise.all to await all API calls
        const apiCalls = validUserIds.map((userId) =>
          UsersQueryApi.getUser(userId)
        );
        const profilesArray = await Promise.all(apiCalls);

        for (let i = 0; i < validUserIds.length; i++) {
          const userId = validUserIds[i];
          const profile = profilesArray[i];
          profiles[userId] = profile;
        }
        setMappedProfiles(profiles);
      } catch (error) {
        console.error('Error fetching user profiles:', error);
      }
    };

    fetchUserProfiles();
  }, []);

  const [selectedProfile, setSelectedProfile] = useState(userProfile);

  const handleProfileSelect = (profile) => {
    setSelectedProfile(profile);
    router.push(`/Accounts/ManageAccount?userId=${selectedProfile.id}`);
    message.success('Profile Switched Successfully');
    return selectedProfile;
  };

  const { logout } = useAuth();

  const getAvatarPhotoUrl = () => {
    if (impersonatedUserData) return impersonatedUserData.profile_photo?.url;
    return userProfile?.profile_photo?.url;
  };
  const avatarPhotoUrl = getAvatarPhotoUrl();

  const getUserDisplayName = () => {
    const dataToUse = impersonatedUserData || userProfile;
    return dataToUse?.first_name?.concat('  ')?.concat(dataToUse?.last_name);
  };
  const userDisplayName = getUserDisplayName();

  return (
    <Popover
      overlayClassName='pop-profile-menu'
      placement='bottom'
      overlayStyle={{
        padding: 16,
        whiteSpace: 'nowrap',
      }}
      content={
        <ProfileMenu>
          <Link href='/profile' passHref legacyBehavior>
            <a>Profile</a>
          </Link>
          <Popover
            overlayClassName='pop-profile-menu'
            placement='left'
            overlayStyle={{
              whiteSpace: 'nowrap',
              padding: 16,
            }}
            content={
              <ProfileMenu>
                {Object.values(mappedProfiles).map((profile) => (
                  <StyledAvatarContent
                    key={profile?.id}
                    onClick={() => handleProfileSelect(profile)}
                  >
                    {profilePhotoUrl ? (
                      <StyledAvatar
                        round={true}
                        color={theme.palette.primary[0]}
                        size='25'
                        src={profile?.profile_photo?.url}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    ) : (
                      <StyledAvatar
                        round={true}
                        color={theme.palette.primary[0]}
                        size='25'
                        name={profile?.first_name
                          ?.concat('  ')
                          ?.concat(profile?.last_name)}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    )}
                    <StyledAvatarText>
                      {profile?.first_name} {profile?.last_name}
                    </StyledAvatarText>
                  </StyledAvatarContent>
                ))}
              </ProfileMenu>
            }
          >
            <a>
              <span>Switch Account</span>
              {GroupIcon && (
                <img
                  src={GroupIcon.src}
                  alt='Group Icon'
                  style={{ height: '24px', minWidth: '24px' }}
                />
              )}
            </a>
          </Popover>
          <Link href='/Accounts/MyAccount' passHref legacyBehavior>
            <a>Settings</a>
          </Link>
          <a onClick={logout}>Log Out</a>
        </ProfileMenu>
      }
    >
      <ProfileAvatar>
        {avatarPhotoUrl ? (
          <Avatar
            round={true}
            color={theme.palette.primary[0]}
            size='35'
            src={avatarPhotoUrl}
            style={{
              cursor: 'pointer',
            }}
          />
        ) : (
          <Avatar
            round={true}
            color={theme.palette.primary[0]}
            size='35'
            name={userDisplayName}
            style={{
              cursor: 'pointer',
            }}
          />
        )}
      </ProfileAvatar>
    </Popover>
  );
};

const ProfileAvatar = styled.div`
  cursor: pointer;

  img {
    object-fit: cover;
  }
`;
const ProfileMenu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  a {
    margin: 0;
    font-size: 14px;
    line-height: ${(props) => props.theme.palette.textLineHeight[3]};
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span {
    margin-right: 10%;
  }
`;
const StyledAvatarContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const StyledAvatarText = styled.span`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-left: 8px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`;
const StyledAvatar = styled(Avatar)`
  max-width: 25px !important;
  height: 25px;
  border-radius: 100%;
`;

export default Profile;
