import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';
// import { InvitesDTO,SubmissionDetails } from '@lib/types/invites';

export class InvitesQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Invites;

  public static getUserInvites(userId: string | number): Promise<InvitesDTO[]> {
    const endpoint = `/user/${userId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
export class InvitesActionQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.InvitedAction;
  private static apiArchived = ApiNames.ArchivedInvites;

  public static getUserInvitesAction(
    userId: string | number
  ): Promise<SubmissionDetails[]> {
    const endpoint = `/user/${userId}?response_type=data&archived_type=submissions`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
  public static getUserInvitesArchived(
    userId: string | number
  ): Promise<SubmissionDetails[]> {
    const endpoint = `/user/${userId}?archived_type=submissions`;
    return this.apiProxy.execute(new ApiRequest(this.apiArchived, endpoint));
  }

  public static getUserInvitesActionType(
    userId: string | number
  ): Promise<InvitesActionTypeDTO> {
    const endpoint = `/user/${userId}?action_type=submitted`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
export class listMemberships {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.listMemberships;
  private static apiSubmit = ApiNames.SubmitApplication;
  private static customQuestions = ApiNames.CustomQuestions;
  public static getListMembership(
    list_membership_id: string | number
  ): Promise<SubmissionDetails> {
    const endpoint = `/${list_membership_id}/details`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
  public static getListMembershipMisc(
    list_membership_id: string | number,
    notification_id: string | number
  ): Promise<SubmissionDetailsMisc> {
    const endpoint = `/${list_membership_id}/details?notification_id=${notification_id}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static updateUserSubmission(
    list_membership_id: string | number,
    data: UpdateUserSubmissionRequest
  ): Promise<updateUserSubmission> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${list_membership_id}`,
        HttpMethod.PUT,
        data
      )
    );
  }

  public static SubmitApplication(
    data: SubmitApplicationRequest
  ): Promise<SumbitApplicationResponse> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiSubmit, `/application`, HttpMethod.POST, data)
    );
  }
  public static SubmitCustomQuestions(
    data: CustomQuestionsRequest
  ): Promise<SumbitApplicationResponse> {
    return this.apiProxy.execute(
      new ApiRequest(this.customQuestions, `/responses`, HttpMethod.POST, data)
    );
  }
}
