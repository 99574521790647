import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import { UsersQueryApi } from '@lib/api/endpoints';
import { AuthUserSession } from '@lib/types/authUser.types';
import { isAuthUserAdmin } from '@lib/utils/authHelper';

export const useImpersonateUser = (
  authUserSession: AuthUserSession
): UseImpersonateUser => {
  const router = useRouter();
  const [impersonatedUserId, setImpersonatedUserId] =
    useState<number | undefined>(undefined);

  const getImpersonatedUser = useCallback(async () => {
    if (!impersonatedUserId) {
      return;
    }
    return await UsersQueryApi.getUser(impersonatedUserId);
  }, [impersonatedUserId]);

  const processImpersonatedUserId = useCallback(() => {
    const isAdmin = isAuthUserAdmin(authUserSession);
    if (!isAdmin) {
      return;
    }
    const newImpersonatedUserId =
      +router.query.impersonate_user_id || undefined;
    setImpersonatedUserId(newImpersonatedUserId);
  }, [authUserSession, setImpersonatedUserId, router.query]);

  return {
    processImpersonatedUserId,
    getImpersonatedUser,
  };
};
