import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { HamburgerMenuIcon } from '@assets/icons';
import { EverysetLogo } from '@assets/img';
import type { EverysetUser } from '@lib/types/user.types';

import Profile from './Profile';

interface HeaderProps {
  toggleCollapsed: () => void;
  impersonatedUserData: EverysetUser | undefined;
}

const Header = ({
  toggleCollapsed,
  impersonatedUserData,
}: HeaderProps): JSX.Element => {
  const router = useRouter();
  const PublicApplication =
    router?.asPath.includes('/apps') ||
    router?.asPath.includes('/apps/[application_id]/SubmitApplication');

  const getImpersonatedUserFullName = () => {
    if (!impersonatedUserData) {
      return '';
    }
    return `${impersonatedUserData.first_name}${
      impersonatedUserData.last_name ? ` ${impersonatedUserData.last_name}` : ''
    }`;
  };

  return (
    <StyledHeader isImpersonating={Boolean(impersonatedUserData)}>
      {!PublicApplication && (
        <HeaderSection>
          <StyledImgButton onClick={toggleCollapsed}>
            <HamburgerMenuIcon
              fill={impersonatedUserData ? 'white' : undefined}
            />
          </StyledImgButton>

          <Link href='/jobs/Invites/' passHref>
            <StyledLogoLink>
              <EverysetLogo
                width={103}
                style={{ cursor: 'pointer', display: 'flex' }}
                fill={impersonatedUserData ? 'white' : undefined}
              />
            </StyledLogoLink>
          </Link>
        </HeaderSection>
      )}
      <HeaderSection>
        {PublicApplication && (
          <EverysetLogo
            width={103}
            style={{ cursor: 'pointer', display: 'flex' }}
          />
        )}
        {impersonatedUserData ? (
          <div className='impersonatingMessage'>
            You are currently impersonating {getImpersonatedUserFullName()}. All
            activity will be logged.
          </div>
        ) : null}
      </HeaderSection>
      {!PublicApplication && (
        <HeaderSection>
          <Profile impersonatedUserData={impersonatedUserData} />
        </HeaderSection>
      )}
    </StyledHeader>
  );
};

type StyledHeaderProps = {
  isImpersonating: boolean;
};

const StyledHeader = styled.header<StyledHeaderProps>`
  background: ${({ theme, isImpersonating }) =>
    isImpersonating ? theme.palette.greyscale[7] : theme.palette.greyscale[1]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 65px;
  box-shadow: ${(props) => props.theme.palette.shadow[0]};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 47;
`;

const HeaderSection = styled.section`
  display: flex;
  align-items: center;
  gap: 16px;

  .impersonatingMessage {
    color: white;
    font-size: 14px;
  }
`;

const StyledLogoLink = styled.a`
  cursor: pointer;
`;

const StyledImgButton = styled.div`
  cursor: pointer;
  margin: 12px;
`;

export default Header;
