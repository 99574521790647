import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';

export class BookingsQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Bookings;
  private static customQuestions = ApiNames.CustomQuestions;

  public static getUserBooked(userId: string | number): Promise<BookingUser[]> {
    const endpoint = `/user/${userId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingDetails(
    booking_date_id: string | number
  ): Promise<BookingDetails> {
    const endpoint = `/${booking_date_id}/details`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingDetailsMisc(
    booking_date_id: string | number,
    notification_id: string | number
  ): Promise<BookingDetails> {
    const endpoint = `/${booking_date_id}/details?notification_id=${notification_id}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static updateBookingSubmission(
    booking_date_id: number | number,
    data: UpdateBookingSubmissionRequest
  ): Promise<updateBookingSubmission> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${booking_date_id}`, HttpMethod.PUT, data)
    );
  }

  public static updateRunningLate(
    booking_date_id: number | number,
    data: RunningLateRequest
  ): Promise<BookingResponse> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${booking_date_id}`, HttpMethod.PUT, data)
    );
  }

  public static CancelRequest(
    booking_date_id: number | number,
    data: CancelRequest
  ): Promise<BookingResponse> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/${booking_date_id}/cancel`,
        HttpMethod.POST,
        data
      )
    );
  }

  public static SubmitCustomQuestions(
    data: CustomQuestionsRequest
  ): Promise<SumbitApplicationResponse> {
    return this.apiProxy.execute(
      new ApiRequest(this.customQuestions, `/responses`, HttpMethod.POST, data)
    );
  }
}

export class BookingsActionQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.BookedAction;
  private static apiArchived = ApiNames.ArchivedBookings;

  public static getUserBookingsAction(
    userId: string | number
  ): Promise<BookingUser[]> {
    const endpoint = `/user/${userId}?response_type=data&action_type=booked`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getUserBookingsArchived(
    userId: string | number
  ): Promise<BookingUser[]> {
    const endpoint = `/user/${userId}?archived_type=bookings`;
    return this.apiProxy.execute(new ApiRequest(this.apiArchived, endpoint));
  }

  public static getUserBookingsActionTypes(
    userId: string | number
  ): Promise<BookingsActionTypeDTO> {
    const endpoint = `/user/${userId}?action_type=booked`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
