import theme from '@styles/theme';

export const UploadIcon = ({
  width = 12,
  height = 12,
  fill = theme.palette.greyscale[6],
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.58578 3.96649C4.32543 4.22684 3.90332 4.22684 3.64297 3.96649C3.38262 3.70614 3.38262 3.28403 3.64297 3.02368L5.52859 1.13806C5.78894 0.877712 6.21105 0.877712 6.4714 1.13806L8.35701 3.02368C8.61736 3.28403 8.61736 3.70614 8.35701 3.96649C8.09666 4.22684 7.67455 4.22684 7.4142 3.96649L6.66664 3.21893V7.49507C6.66664 7.86326 6.36817 8.16174 5.99998 8.16174C5.63179 8.16174 5.33331 7.86326 5.33331 7.49507V3.21896L4.58578 3.96649Z'
        fill={fill}
      />
      <path
        d='M0.666626 10.9697C0.666626 11.1705 0.829432 11.3333 1.03026 11.3333L10.9697 11.3333C11.1705 11.3333 11.3333 11.1705 11.3333 10.9697V7.99998C11.3333 7.63179 11.0348 7.33331 10.6666 7.33331C10.2984 7.33331 9.99996 7.63179 9.99996 7.99998V9.99998H1.99996V7.99998C1.99996 7.63179 1.70148 7.33331 1.33329 7.33331C0.965103 7.33331 0.666626 7.63179 0.666626 7.99998V10.9697Z'
        fill={fill}
      />
    </svg>
  );
};
