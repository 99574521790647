import { CSSProperties } from 'react';

export type LogoProps = {
  width?: number | string;
  style?: React.CSSProperties;
  onClick?: () => void;
  fill?: string;
};

const INITIAL_WIDTH = 136;

const EverysetLogo = ({
  width = INITIAL_WIDTH,
  onClick,
  style,
  fill = '#1733C4',
}: LogoProps): JSX.Element => {
  const logoStyle: React.CSSProperties = {
    width: typeof width === 'number' ? `${width}px` : width,
    cursor: onClick ? 'pointer' : 'auto', // Add cursor style
    ...style, // Merge with other provided styles
  };
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      viewBox='0 0 320 87'
      fill='none'
      style={logoStyle}
      onClick={onClick}
    >
      <g clipPath='url(#clip0_2225_20835)'>
        <path
          d='M308.458 0H319.012C319.48 0 319.948 0.363937 319.948 0.935839V4.73119C319.948 5.19911 319.584 5.66703 319.012 5.66703H306.638L307.47 0.727875C307.678 0.311946 308.094 0 308.458 0Z'
          fill={fill}
        />
        <path
          d='M298.736 5.71875H306.742L305.703 11.4378H298.736C298.268 11.4378 297.8 11.0738 297.8 10.5019V6.6026C297.8 6.08269 298.164 5.71875 298.736 5.71875Z'
          fill={fill}
        />
        <path
          d='M305.703 11.4414H319.012C319.48 11.4414 319.948 11.8053 319.948 12.3772V16.1726C319.948 16.6405 319.584 17.1084 319.012 17.1084H304.663L305.703 11.4414Z'
          fill={fill}
        />
        <path
          d='M298.736 17.1045H304.715L303.675 22.8235H298.736C298.268 22.8235 297.8 22.4596 297.8 21.8877V17.9883C297.8 17.5204 298.164 17.1045 298.736 17.1045Z'
          fill={fill}
        />
        <path
          d='M303.675 22.8252H319.064C319.532 22.8252 320 23.1891 320 23.761V27.5564C320 28.0243 319.636 28.4922 319.064 28.4922H303.727C303.155 28.4922 302.791 28.0243 302.895 27.4524L303.675 22.8252Z'
          fill={fill}
        />
        <path
          d='M0 9.02246H37.7168L35.8174 19.2657H10.7859V32.2902H32.0186V42.6013H10.7859V55.6258H37.7168V65.9368H0V9.02246Z'
          fill={fill}
        />
        <path
          d='M126.311 65.8704V39.8214C126.311 32.0881 130.788 27.6787 138.453 27.6787C143.27 27.6787 146.322 27.6787 146.322 27.6787L144.626 37.2436H140.488C138.114 37.2436 136.35 38.9395 136.35 41.3816V65.8704H126.311Z'
          fill={fill}
        />
        <path
          d='M79.1645 27.8164L63.9015 66.0759H53.319L38.0559 27.8164H49.0454L58.6102 53.7297L68.3786 27.8842H79.1645V27.8164Z'
          fill={fill}
        />
        <path
          d='M119.595 47.0111C119.595 47.893 119.595 49.0462 119.391 50.0637H90.629C91.8501 54.6766 95.2419 57.9327 100.94 57.9327C104.874 57.9327 108.334 56.3724 110.166 53.659L117.017 59.1537C113.761 63.6309 108.809 66.887 101.076 66.887C87.5086 66.887 80.5215 57.1186 80.5215 46.6719C80.5215 36.3608 88.3904 26.9316 99.8547 26.9316C112.269 26.9995 119.595 35.886 119.595 47.0111ZM109.487 42.9409C108.605 38.8029 105.688 36.2252 100.262 36.2252C95.7845 36.2252 92.1892 38.8029 90.8325 42.9409H109.487Z'
          fill={fill}
        />
        <path
          d='M189.534 27.8164L166.198 86.2232H156.091L163.824 66.6864L148.222 27.8164H159.347L168.912 53.6619L178.477 27.8164H189.534Z'
          fill={fill}
        />
        <path
          d='M205.475 57.8639C208.935 57.8639 210.088 56.5072 210.088 55.1505C210.088 49.5201 191.433 52.9119 191.433 39.209C191.433 32.1541 196.589 27.0664 205.136 27.0664C211.309 27.0664 216.804 28.9658 219.856 31.8828L214.701 39.0734C211.987 37.174 208.867 36.1564 205.272 36.1564C202.219 36.1564 201.337 37.174 201.337 38.5307C201.337 43.8219 219.653 41.2441 219.653 55.0148C219.653 62.0698 214.497 67.1575 204.933 67.1575C197.742 67.1575 193.265 64.444 189.669 61.8663L194.825 54.133C198.624 56.9821 202.762 57.8639 205.475 57.8639Z'
          fill={fill}
        />
        <path
          d='M263.543 47.0111C263.543 47.893 263.543 49.0462 263.339 50.0637H234.712C235.933 54.6766 239.325 57.9327 245.023 57.9327C248.958 57.9327 252.417 56.3724 254.249 53.659L261.1 59.1537C257.844 63.6309 252.892 66.887 245.159 66.887C231.592 66.887 224.605 57.1186 224.605 46.6719C224.605 36.3608 232.474 26.9316 243.938 26.9316C256.352 26.9995 263.543 35.886 263.543 47.0111ZM253.639 42.9409C252.757 38.8029 249.84 36.2252 244.413 36.2252C239.936 36.2252 236.34 38.8029 234.984 42.9409H253.639Z'
          fill={fill}
        />
        <path
          d='M270.259 53.8609C270.259 61.5942 274.736 66.0035 282.401 66.0035C287.218 66.0035 290.27 66.0035 290.27 66.0035V56.9135C290.27 56.9135 287.218 56.9135 284.776 56.9135C281.519 56.9135 280.502 55.3533 280.502 52.0971V37.4446H289.388L291.084 27.8797H280.434V14.3125H270.327V53.8609H270.259Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2225_20835'>
          <rect width='320' height='87' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EverysetLogo;
