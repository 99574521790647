import { API, Auth } from 'aws-amplify';

import type { CognitoUser } from '@aws-amplify/auth';

import type { ApiRequest } from './apiRequest';
import { AuthToken } from './authToken';
import { awsApiConfig } from './aws';
import { HttpMethod } from './httpMethod';

API.configure(awsApiConfig);

export class ApiProxy {
  private static instance: ApiProxy;
  private authToken: AuthToken;

  public static getInstance(): ApiProxy {
    if (!ApiProxy.instance) ApiProxy.instance = new ApiProxy();
    return ApiProxy.instance;
  }

  private setToken(token: AuthToken): void {
    this.authToken = token;
    const timeout = 1000 * (token.expiration - 60) - Date.now();
    setTimeout(this.refreshTokens.bind(this), timeout);
  }

  private async getToken(): Promise<AuthToken> {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const session = user.getSignInUserSession();
    const token = session.getIdToken();
    const refreshToken = session.getRefreshToken();
    const tokenValue = token.getJwtToken();
    const {
      payload: { exp },
    } = token;
    return new AuthToken(tokenValue, exp, refreshToken);
  }

  private async refreshTokens(): Promise<void> {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    cognitoUser.refreshSession(this.authToken.refreshToken, (err, session) => {
      if (err) {
        Auth.signOut();
        localStorage.removeItem('currentProject');
      }
      const { refreshToken } = session;
      const expiration = session.getIdToken().payload.exp;
      const authToken = new AuthToken(
        session.getIdToken().getJwtToken(),
        expiration,
        refreshToken
      );
      this.setToken(authToken);
    });
  }

  private httpCall<S>(type: HttpMethod): ApiCall<S> {
    const HTTP_FUNCTION_MAPPER = {
      [HttpMethod.GET]: API.get,
      [HttpMethod.POST]: API.post,
      [HttpMethod.PUT]: API.put,
      [HttpMethod.DELETE]: API.del,
    };
    return HTTP_FUNCTION_MAPPER[type].bind(API);
  }

  private buildHttpParams(request: ApiRequest): HttpParams {
    return {
      body: request.body,
      headers: {
        Authorization: `Bearer ${this.authToken.jwtToken}`,
        ...request.httpHeaders,
      },
      ...request.miscOptions,
    };
  }

  public async execute<ResourceType>(
    request: ApiRequest
  ): Promise<ResourceType> {
    if (!this.authToken) this.setToken(await this.getToken());

    const params = this.buildHttpParams(request);
    const call = this.httpCall<ResourceType>(request.method);
    return call(request.apiName, request.url, params)
      .then((res) => res)
      .catch((ish) => {
        throw new Error(ish?.response?.data?.error);
      });
  }
}
