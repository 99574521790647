import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useOnboardingStateContext } from 'context/onboarding/OnboardingStateContext';
import PublicApplicationIndex from 'pages/apps/[application_id]/index';
import styled from 'styled-components';

import { EverysetLogo } from '@assets/img';
import { useAuth } from '@lib/auth';

import LoginIndex from './login';

interface IProps {
  children: ReactNode;
  routeProtected: boolean;
}

const ProtectRoute = ({ children, routeProtected }: IProps): JSX.Element => {
  const router = useRouter();
  const { isAuthenticated, sessionLoading } = useAuth();
  const { onboardingLoading } = useOnboardingStateContext();
  const routeSegments = router?.asPath.split('/');
  const isPublicApplicationRoute =
    router?.asPath.includes('/apps') &&
    (!routeSegments[3] || routeSegments[3] !== 'SubmitApplication');

  if (onboardingLoading || sessionLoading) return <SplashScreen />;
  if (isPublicApplicationRoute) return <PublicApplicationIndex />;
  if (!isAuthenticated && routeProtected) return <LoginIndex />;
  return <>{children}</>;
};

const SplashScreen = (): JSX.Element => (
  <StyledContainer>
    <EverysetLogo width='20vw' />
  </StyledContainer>
);

const StyledContainer = styled.div`
  height: 100vh;
  display: grid;
  place-content: center;
`;

export default ProtectRoute;
