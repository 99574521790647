export const ART = [
  'Art',
  'Artist',
  'Crocheting',
  'Knitting',
  'Sculpting',
] as const;

export const DANCE = [
  'Dance',
  'Ballet',
  'Ballroom',
  'Breakdancing',
  'Contemporary',
  'Dance- Other',
  'Hip Hop',
  'Jazz',
  'Tap',
] as const;

export const Experience = [
  'Experience',
  'Barista',
  'Bartending',
  'Cab Driver',
  'Cameraman',
  'Catering',
  'Chef',
  'DJ',
  'Doctor',
  'EMT / Paramedic',
  'Fencing',
  'Firearms',
  'Firefighter',
  'Hairstylist',
  'Lifeguard',
  'Makeup Artist',
  'Manicurist',
  'Massage Therapy',
  'Military / Veteran',
  'Model',
  'Nursing',
  'Precision Driving',
  'Stenographer',
  'Stunts',
  'Waiting Tables',
  'Weapons',
] as const;

export const Music = [
  'Music',
  'Drums',
  'Guitar',
  'Marching Band',
  'Mariachi',
  'Orchestra',
  'Other Instrument',
  'Piano',
] as const;

export const Performative = [
  'Performative',
  'Aerial',
  'Belly Dancing',
  'Burlesque',
  'Casino Dealer',
  'Clown',
  'Contortion',
  'Flare Bartending',
  'Juggling',
  'Magician',
  'Mime',
  'Pole Dancing',
  'Stilt Walker',
  'Theater',
  'Unicycle',
] as const;

export const Sport = [
  'Sport',
  'Archery',
  'Badminton',
  'Baseball',
  'Basketball',
  'Billiards',
  'Body Building',
  'Bowling',
  'Boxing',
  'Cheerleading',
  'Cross Country',
  'Crossfit',
  'Cycling',
  'Football',
  'Frisbee',
  'Golf',
  'Gymnastics',
  'Hockey',
  'Horseback Riding',
  'Ice Skating',
  'Jiujitsu',
  'Jump Rope',
  'Kayaking',
  'Kick Boxing',
  'Martial Arts',
  'MMA',
  'Paddle Boarding',
  'Parkour',
  'Pickleball',
  'Ping Pong',
  'Rock Climber',
  'Roller Blading',
  'Roller Skating',
  'Rugby',
  'Scuba Diving',
  'Skateboarding',
  'Skiing - Snow',
  'Skiing - Water',
  'Snowboarding',
  'Soccer',
  'Softball',
  'Surfing',
  'Swimming',
  'Tennis',
  'Track',
  'Volleyball',
  'Wrestling',
  'Yoga',
] as const;

export const Languages = [
  'Languages',
  'Arabic',
  'Bengali',
  'Chinese',
  'Czech',
  'Dutch',
  'English',
  'Farsi',
  'French',
  'German',
  'Greek',
  'Haitian Creole French',
  'Hindi',
  'Indo-Iranian',
  'Italian',
  'Japanese',
  'Javanese',
  'Korean',
  'North Germanic',
  'Panjabi',
  'Polish',
  'Portuguese',
  'Romanian',
  'Russian',
  'Sign Language',
  'Spanish',
  'Tagalog',
  'Thai',
  'Turkish',
  'Ukrainian',
  'Urdu',
  'Vietnamese',
  'Language - Other',
] as const;
