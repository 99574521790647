import { Amplify } from 'aws-amplify';

import awsconfig from '../aws-exports';

export default function configureAmplify(): void {
  const update = {
    ...awsconfig,
    aws_user_pools_id: process.env.NEXT_PUBLIC_USERPOOL_ID,
    aws_cognito_region: 'us-west-2',
    aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    aws_cognito_social_providers: ['GOOGLE'],
    oauth: {
      domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
      redirectSignIn: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGN_IN,
      // typeof window !== 'undefined'
      //   ? window.location.href
      //   : process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGN_OUT,
      // typeof window !== 'undefined'
      //   ? window.location.href
      //   : process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGN_OUT,
      scope: ['email', 'openid', 'profile'],
      responseType: 'code',
      federationTarget: 'COGNITO_USER_POOLS',
    },
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  };
  console.debug('Update', update);
  Amplify.configure(update);
  return;
}
