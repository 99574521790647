'use client';
import { ReactElement } from 'react';

import * as Sentry from '@sentry/nextjs';
interface IErrorOptions {
  level?: Sentry.SeverityLevel;
  catchType?: 'request';
}
// a function that child components can call
// to send errors back up the tree to our logging service
const ErrorHandler = (error, options?: IErrorOptions, handler?: () => void) => {
  if (!Sentry.getCurrentHub().getClient()) {
    // failsafe for if Sentry is down
    // do stuff here
    console.log('API call to our custom AWS logging service here');
  } else {
    console.error(error);
    const { level, catchType } = options;
    // designate temporary scope/context
    Sentry.withScope((scope) => {
      if (level) scope.setLevel(level);
      if (catchType === 'request') {
        const response = error?.response;
        // create fingerprint identifier
        // to group errors together based on
        // their request and response
        scope.setFingerprint([
          response?.config?.method,
          response?.request?.responseURL,
          String(error?.status),
        ]);
      }
      Sentry.captureException(error); // send error to Sentry
    });
  }
};

// Higher Order Component
export const withErrorHandling = (
  WrappedComponent: (props: any) => ReactElement
): ((props) => ReactElement) => {
  return function Component(props) {
    return <WrappedComponent catchErrors={ErrorHandler} {...props} />;
  };
};
