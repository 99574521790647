import { ReactElement, useRef } from 'react';
import { Input } from 'antd';
import type { TextAreaProps } from 'antd/es/input';
import styled from 'styled-components';

import { Label } from '../Label';

const { TextArea: AntTextArea } = Input;

interface IProps extends TextAreaProps {
  label?: string;
  requiredSymbol?: boolean;
  valid?: boolean;
  width?: number | string;
  height?: number | string;
  autofit?: boolean;
  isMobile?: boolean;
}

const TextArea = (props: IProps): ReactElement => {
  const { label, requiredSymbol, autofit = false, ...res } = props;

  const ref = useRef(null);

  // resize textarea to the height of content
  function fitToContent() {
    if (!ref) return;
    const textArea = ref.current.resizableTextArea.textArea;

    const adjustedHeight = Math.max(
      textArea.scrollHeight,
      textArea.clientHeight
    );
    if (adjustedHeight > textArea.clientHeight)
      textArea.style.height = adjustedHeight + 'px';
  }

  return (
    <>
      {label && (
        <>
          <Label label={label} requiredSymbol={requiredSymbol} />
          <br />
        </>
      )}
      {autofit ? (
        <StyledTextArea
          autofit
          ref={ref}
          onInput={() => fitToContent()}
          {...res}
        />
      ) : (
        <StyledTextArea {...res} />
      )}
    </>
  );
};

const StyledTextArea = styled(AntTextArea)<IProps>`
  &.ant-input {
    padding: 10px 8px;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '35px')};
    background: ${(props) => props.theme.palette.greyscale[0]};
    font-size: ${(props) =>
      props.isMobile
        ? props.theme.palette.textFontSize[3]
        : props.theme.palette.textFontSize[7]};
    line-height: ${(props) =>
      props.isMobile
        ? props.theme.palette.textLineHeight[2]
        : props.theme.palette.textLineHeight[5]};
    color: ${(props) => props.theme.palette.text[0]};
    border: 1px solid
      ${({ theme, required, valid, value }) =>
        valid === false || (required && !value)
          ? theme.palette.color[0]
          : theme.palette.greyscale[3]};
    border-radius: 4px;
    overflow: ${(props) => (props.autofit ? 'hidden' : 'auto')};

    &.ant-input-disabled,
    &.ant-input-disabled:hover {
      background: ${(props) => props.theme.palette.greyscale[2]};
      border-color: ${(props) => props.theme.palette.greyscale[3]};
      color: ${(props) => props.theme.palette.text[3]};
    }

    &::placeholder {
      color: ${(props) => props.theme.palette.text[3]};
    }

    &:hover {
      border-color: ${(props) => props.theme.palette.primary[0]};
    }

    &:focus,
    &:active {
      border-color: ${(props) => props.theme.palette.primary[0]};
      box-shadow: ${(props) => props.theme.palette.shadow[1]};
    }
  }
`;

export { TextArea };
