import { CognitoRefreshToken } from 'amazon-cognito-identity-js';

export class AuthToken {
  jwtToken: string;
  expiration: number;
  refreshToken?: CognitoRefreshToken | null;

  constructor(
    jwtToken: string,
    expiration: number,
    refreshToken: CognitoRefreshToken = null
  ) {
    this.jwtToken = jwtToken;
    this.expiration = expiration;
    this.refreshToken = refreshToken;
  }
}
