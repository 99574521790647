import { InputNumber } from 'antd';
import styled from 'styled-components';

export const NumericInput = styled(InputNumber)`
  &.ant-input-number {
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '35px')};
    background: ${(props) => props.theme.palette.greyscale[0]};
    font-size: ${(props) => props.theme.palette.textFontSize[7]};
    line-height: ${(props) => props.theme.palette.textLineHeight[5]};
    border-radius: 4px;
    display: block;

    .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 100%;
      &::placeholder {
        color: ${(props) => props.theme.palette.text[3]};
      }
    }

    &.ant-input-disabled,
    &.ant-input-disabled:hover {
      //background: ${(props) => props.theme.palette.greyscale[0]};
      border-color: ${(props) => props.theme.palette.greyscale[3]};
      color: ${(props) => props.theme.palette.text[3]};
    }

    &:hover {
      // border-color: ${(props) => props.theme.palette.primary[0]};
    }

    &:focus,
    &:active {
      border-color: ${(props) => props.theme.palette.primary[0]};
      box-shadow: ${(props) => props.theme.palette.shadow[1]};
    }
  }

  .ant-input-number-wrapper .ant-input-number-group-addon {
    font-size: ${(props) => props.theme.palette.textFontSize[7]};
    color: ${(props) => props.theme.palette.text[0]};
  }
`;
