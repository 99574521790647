import React from 'react';
import styled, { css } from 'styled-components';

import { unsortedArraysAreEqual } from '@lib/utils/arrayHelper';
import { ETHNICITIES, GENDER_IDENTITIES } from '@lib/utils/constants';

import EverysetBlue from '../../svg/EverysetBlue.png';

import { FORM_WIDTH } from './[application_id]';

const COLUMN_WIDTH = 278;

const DetailTable = ({ data }) => {
  const Dates = data?.dates;
  const groupedData = Dates.reduce((acc, item) => {
    const unitName = item.unit_name;
    if (!acc[unitName]) {
      acc[unitName] = [];
    }
    acc[unitName].push(item.dates);
    return acc;
  }, {});

  const getFormattedDateRange = (dates) => {
    return dates
      .map((date) => {
        const [year, month, day] = date.toString().split('-');
        return `${parseInt(month)}/${parseInt(day)}`;
      })
      .join(', ');
  };

  const sortedCellData = Object.keys(groupedData)
    .map((unitName) => ({
      label: unitName,
      dates: getFormattedDateRange(groupedData[unitName][0]),
      length: groupedData[unitName][0]?.length || 0,
    }))
    .filter((cellData) => cellData.dates)
    .sort((a, b) => {
      const datesA = a.dates;
      const datesB = b.dates;
      return datesA.localeCompare(datesB);
    });

  const firstRowData = sortedCellData.slice(0, 3);
  const secondRowData = sortedCellData.slice(3, 6);
  const thirdRowData = sortedCellData.slice(6);
  const isMobileDevice = window.innerWidth <= 768;

  return (
    <>
      <TableRow>
        {!isMobileDevice && (
          <img
            src={EverysetBlue.src}
            alt='everyset-logo'
            height={84}
            width={84}
            style={{ marginBottom: 16, marginTop: 40 }}
          />
        )}
      </TableRow>
      <TableRow>
        {!isMobileDevice && (
          <Title>
            NOW CASTING: {Object.keys(data).length && data?.job_name}
          </Title>
        )}
      </TableRow>
      <CenteredForm style={{ width: !isMobileDevice && FORM_WIDTH }}>
        <TableDetail>
          <tbody>
            {isMobileDevice ? (
              <>
                <TableRow>
                  <TableCell>
                    <Label>
                      <LabelText>Type</LabelText>
                      <ValueText>
                        {data?.role?.type ? data?.role?.type : '-'}
                      </ValueText>{' '}
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      <LabelText>Role</LabelText>
                      <ValueText>
                        {data?.role?.name ? data?.role?.name : '-'}
                      </ValueText>
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      <LabelText>Voucher</LabelText>
                      <ValueText>
                        {data?.union_status ? data?.union_status : '-'}
                      </ValueText>
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      <LabelText>SAG Rate</LabelText>
                      <ValueText>
                        {Object.keys(data?.union_rate).length
                          ? data?.union_rate
                          : '-'}
                      </ValueText>
                    </Label>
                    <Label>
                      <LabelText>N/U Rate</LabelText>
                      <ValueText>
                        {Object.keys(data?.rate).length ? data?.rate : '-'}
                      </ValueText>
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Label>
                      <LabelText>Ages Portrayable</LabelText>
                      <ValueText>
                        {data?.ages
                          ? data?.ages?.min + '-' + data?.ages?.max
                          : 'Any'}
                      </ValueText>
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      <LabelText>Genders Portrayable</LabelText>
                      <ValueText>
                        {data?.genders?.length === 0 ||
                        unsortedArraysAreEqual<string>(
                          GENDER_IDENTITIES,
                          data?.genders
                        ) ? (
                          <ValueText>Any</ValueText>
                        ) : (
                          <ValueText>{data?.genders?.join(', ')}</ValueText>
                        )}
                      </ValueText>
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      <LabelText>Ethnicities Portrayable</LabelText>
                      <ValueText>
                        {data?.ethnicities?.length === 0 ||
                        unsortedArraysAreEqual<string>(
                          ETHNICITIES,
                          data?.ethnicities || []
                        ) ? (
                          <ValueText>Any</ValueText>
                        ) : (
                          <ValueText>{data?.ethnicities?.join(', ')}</ValueText>
                        )}
                      </ValueText>
                    </Label>
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell style={{ width: COLUMN_WIDTH }}>
                    <table>
                      <tbody>
                        <tr style={{ display: 'flex', gap: 32 }}>
                          <td>
                            <Label>
                              <LabelText>Type</LabelText>
                              <ValueText style={{ whiteSpace: 'nowrap' }}>
                                {data?.role?.type ? data?.role?.type : '-'}
                              </ValueText>
                            </Label>
                          </td>
                          <td>
                            <Label>
                              <LabelText>Role</LabelText>
                              <ValueText>
                                {data?.role?.name ? data?.role?.name : '-'}
                              </ValueText>
                            </Label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </TableCell>
                  <TableCell style={{ width: COLUMN_WIDTH }}>
                    <Label>
                      <LabelText>Voucher</LabelText>
                      <ValueText>
                        {data?.union_status ? data?.union_status : '-'}
                      </ValueText>
                    </Label>
                  </TableCell>
                  <TableCell
                    style={{ display: 'flex', gap: 92, width: COLUMN_WIDTH }}
                  >
                    <Label>
                      <LabelText>SAG Rate</LabelText>
                      <ValueText>
                        {Object.keys(data?.union_rate).length
                          ? data?.union_rate
                          : '-'}
                      </ValueText>
                    </Label>
                    <Label>
                      <LabelText>N/U Rate</LabelText>
                      <ValueText>
                        {Object.keys(data?.rate).length ? data?.rate : '-'}
                      </ValueText>
                    </Label>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={1}
                    style={{
                      verticalAlign: 'baseline',
                    }}
                  >
                    <Label>
                      <LabelText>Ages Portrayable</LabelText>
                      <ValueText>
                        {Object.keys(data?.ages || {}).length
                          ? data?.ages?.min + '-' + data?.ages?.max
                          : 'Any'}
                      </ValueText>
                    </Label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Label>
                      <LabelText>Genders Portrayable</LabelText>
                      {data?.genders?.length === 0 ||
                      unsortedArraysAreEqual<string>(
                        GENDER_IDENTITIES,
                        data?.genders ?? []
                      ) ? (
                        <ValueText>Any</ValueText>
                      ) : (
                        <ValueText>{data?.genders?.join(', ')}</ValueText>
                      )}
                    </Label>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Label>
                      <LabelText>Ethnicities Portrayable</LabelText>
                      {data?.ethnicities?.length === 0 ||
                      unsortedArraysAreEqual<string>(
                        ETHNICITIES,
                        data?.ethnicities || []
                      ) ? (
                        <ValueText>Any</ValueText>
                      ) : (
                        <ValueText>{data?.ethnicities?.join(', ')}</ValueText>
                      )}
                    </Label>
                  </TableCell>
                </TableRow>
              </>
            )}
            {isMobileDevice ? (
              <React.Fragment>
                <TableRow>
                  {firstRowData.map((cellData, index) => (
                    <TableCell key={index}>
                      <Label>
                        <LabelText>
                          {cellData.label} Dates ({cellData.length || 0})
                        </LabelText>
                        <ValueText>{cellData.dates || ' '}</ValueText>
                      </Label>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {secondRowData.map((cellData, index) => (
                    <TableCell key={index}>
                      <Label>
                        <LabelText>
                          {cellData.label} Dates ({cellData.length || 0})
                        </LabelText>
                        <ValueText>{cellData.dates || ' '}</ValueText>
                      </Label>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow style={{ display: 'flex' }}>
                  {thirdRowData.map((cellData, index) => (
                    <TableCell key={index}>
                      <Label>
                        <LabelText>
                          {cellData.label} Dates ({cellData.length || 0})
                        </LabelText>
                        <ValueText>{cellData.dates || ' '}</ValueText>
                      </Label>
                    </TableCell>
                  ))}
                  <TableCell colSpan={3} style={{ wordBreak: 'break-word' }}>
                    <Label>
                      <LabelText>Description</LabelText>
                      <ValueText>
                        {data?.description ? data?.description : '-'}
                      </ValueText>
                    </Label>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableRow>
                  {firstRowData.map((cellData, index) => (
                    <TableCell
                      key={index}
                      style={{ verticalAlign: 'baseline' }}
                    >
                      <Label>
                        <LabelText>
                          {cellData.label} Dates ({cellData.length || 0})
                        </LabelText>
                        <ValueText>
                          {cellData.dates
                            ? cellData.dates.split(',').map((date, i) => (
                                <span key={i}>
                                  {date}
                                  {i !== cellData.dates.split(',').length - 1 &&
                                    ', '}
                                  {i % 5 === 4 && <br />}
                                </span>
                              ))
                            : ' '}
                        </ValueText>
                      </Label>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {secondRowData.map((cellData, index) => (
                    <TableCell
                      key={index}
                      style={{ verticalAlign: 'baseline' }}
                    >
                      <Label>
                        <LabelText>
                          {cellData.label} Dates ({cellData.length || 0})
                        </LabelText>
                        <ValueText>
                          {cellData.dates
                            ? cellData.dates.split(',').map((date, i) => (
                                <span key={i}>
                                  {date}
                                  {i !== cellData.dates.split(',').length - 1 &&
                                    ', '}
                                  {i % 5 === 4 && <br />}
                                </span>
                              ))
                            : ' '}
                        </ValueText>
                      </Label>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {thirdRowData.map((cellData, index) => (
                    <TableCell
                      key={index}
                      style={{ verticalAlign: 'baseline' }}
                    >
                      <Label>
                        <LabelText>
                          {cellData.label} Dates ({cellData.length || 0})
                        </LabelText>
                        <ValueText>
                          {cellData.dates
                            ? cellData.dates.split(',').map((date, i) => (
                                <span key={i}>
                                  {date}
                                  {i !== cellData.dates.split(',').length - 1 &&
                                    ', '}
                                  {i % 5 === 4 && <br />}
                                </span>
                              ))
                            : ' '}
                        </ValueText>
                      </Label>
                    </TableCell>
                  ))}
                  <TableCell colSpan={3} style={{ wordBreak: 'break-word' }}>
                    <Label>
                      <LabelText>Description</LabelText>
                      <ValueText>
                        {data?.description ? data?.description : '-'}
                      </ValueText>
                    </Label>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
          </tbody>
        </TableDetail>
      </CenteredForm>
    </>
  );
};

export default (props: any) => (
  <ResponsiveTableDetail responsive>
    <DetailTable responsive {...props} />
  </ResponsiveTableDetail>
);
const mobileBreakpoint = '768px';

const TableDetail = styled.table<{ responsive?: boolean }>`
  border-collapse: collapse;
  width: 100%;

  ${({ responsive }) =>
    responsive &&
    css`
      @media (max-width: ${mobileBreakpoint}) {
        display: block;
        width: 100%;
      }
    `}
`;

const TableRow = styled.div`
  display: flex;
  column-gap: 32px;
`;

const TableCell = styled.td<{
  align?: string;
  colspan?: string;
  rowspan?: string;
}>`
  width: 100%;
  ${({ align }) => align && `text-align: ${align};`}
  ${({ colspan }) => colspan && `grid-column-end: span ${colspan};`}
  ${({ rowspan }) => rowspan && `grid-row-end: span ${rowspan};`}
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueText = styled.span`
  margin-top: 4px;
  font-size: 14px;
`;

const LabelText = styled.span`
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  color: #868686;
`;

const ResponsiveTableDetail = styled(TableDetail)`
  @media (max-width: ${mobileBreakpoint}) {
    & ${TableRow} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const CenteredForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 0px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
`;
