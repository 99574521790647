import { ReactElement, useEffect, useState } from 'react';
import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import { Divider, message, Row } from 'antd';
import { Tooltip } from 'antd';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { GoogleLogo } from '@assets/icons';
import { EverysetLogo } from '@assets/img';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Button } from '@components/Button';
import { Form } from '@components/Form';
import { Input, PasswordInput } from '@components/Input';
import { resetStore } from '@lib/redux.lib/slices/appSlice';

interface IFieldValues {
  email?: string;
  password?: string;
}

const getApplicationIdFromUrl = (url: string) => {
  if (!url) return;
  const searchString = 'apps/';
  const index = url.indexOf(searchString);
  const applicationId = url.substring(index + searchString.length);

  return applicationId.split(/\?|\//gi)[0];
};

const signIn = async (
  fieldValues: IFieldValues,
  setSignInLoading: (loading: boolean) => void,
  router: NextRouter,
  redirectTo: string | undefined
): Promise<void> => {
  await (Auth as any)._storage.clear();
  await (Auth as any).cleanCachedItems();
  // disable Log In button while loading to prevent multiple clicks
  setSignInLoading(true);

  const { email, password } = fieldValues;

  const username = email?.toLowerCase()?.trim();
  const url = router.asPath;
  const { redirectTo: redirectToInQuery } = router.query;
  const applicationIdFromUrl = getApplicationIdFromUrl(url);
  const { PostApplication, application_id } = router.query;
  const postApplicationAuth = router?.asPath.includes('/apps');

  try {
    // SignIn on Cognito -- will trigger event for listener in @lib/auth/index.tsx
    await Auth.signIn(username, password);

    message.info('Successfully logged in!');

    // populate redux with user to conditional render content
    // TODO

    setSignInLoading(false);

    // if prev redirected to login (e.g. from signup page)
    // then redirect to home page on sign in
    if (redirectTo) {
      router.push(redirectTo);
      return;
    }

    if (PostApplication || postApplicationAuth) {
      router.push(
        `/apps/${application_id || applicationIdFromUrl}/SubmitApplication`
      );
    }
    if (router.pathname === '/auth/login') {
      router.push('/');
    }
  } catch (error) {
    if (error?.code === 'UserNotConfirmedException') {
      try {
        await Auth.resendSignUp(username);
        if (url?.includes('/apps/') || redirectToInQuery?.includes('/apps/')) {
          router.push({
            pathname: '/auth/post-signup',
            query: { email: username, redirectTo: url ?? redirectToInQuery },
          });
          return;
        }
        // next js router will url encode params -- need to pass as string literal
        router.push(`/auth/post-signup?email=${username}`);
      } catch (resendError) {
        console.error(resendError); // intentional
      } finally {
        setSignInLoading(false);
      }
      return;
    }
    console.error(error); // intentional
    displayError(error?.message);
    setSignInLoading(false);
  }
};

const displayError = (ish: string) => {
  message.error(ish.replace(/[\S]* failed with error/, ''), 3);
};

const EmailFormItem = (): ReactElement => (
  <Form.Item
    name={'email'}
    rules={[
      {
        required: true,
        message: 'Email is required!',
        validator: async (rules, email): Promise<any> =>
          email ? Promise.resolve() : Promise.reject(),
      },
    ]}
    style={{ maxWidth: 352, margin: '0 0 16px 0' }}
  >
    <Input
      name='email'
      label='Email'
      labelStyle={{ fontWeight: 600, fontSize: 12 }}
      requiredSymbol
      placeholder='name@example.com'
      style={{ maxWidth: 352, height: 35, margin: '4px 0 0 0' }}
    />
  </Form.Item>
);

const PasswordFormItem = (): ReactElement => (
  <Form.Item
    name={'password'}
    rules={[
      {
        required: true,
        message: 'Password is required!',
        validator: async (rules, password): Promise<any> => {
          if (password) return Promise.resolve();
          return Promise.reject();
        },
      },
    ]}
    style={{
      maxWidth: 352,
      margin: '0 0 8px 0',
    }}
  >
    <PasswordInput
      name='password'
      label='Password'
      labelStyle={{ fontWeight: 600, fontSize: 12 }}
      placeholder='*****'
      requiredSymbol
      style={{
        maxWidth: 352,
        height: 35,
        margin: '4px 0 0 0',
      }}
    ></PasswordInput>
  </Form.Item>
);

const ForgotPasswordLink = (): ReactElement => (
  <div style={{ display: 'flex', maxWidth: 352, margin: '0 0 16px 0' }}>
    <StyledLink href='/auth/forgot-password'>Forgot your password?</StyledLink>{' '}
  </div>
);

const SignInFormItem = ({
  signInLoading,
}: {
  signInLoading: boolean;
}): ReactElement => (
  <Form.Item
    style={{
      maxWidth: 352,
      margin: 0,
    }}
  >
    <Button
      type='primary'
      htmlType='submit'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 0,
        maxWidth: '100%',
        width: 352,
        height: 36,
        fontSize: 12,
      }}
      disabled={signInLoading}
    >
      Log in
    </Button>
  </Form.Item>
);

const GoogleButton = ({ router }): ReactElement => {
  const isFacebookBrowser =
    navigator?.userAgent?.includes('FB_IAB') ||
    navigator?.userAgent?.includes('FBIOS') ||
    navigator?.userAgent?.includes('FBAV') ||
    navigator?.userAgent?.includes('FBAN');

  return (
    <Tooltip
      title={
        isFacebookBrowser
          ? 'Google Sign In is not supported in Facebook Browser. Please open the application link with your native browser, such as Chrome or Safari, or on a laptop/computer.'
          : null
      }
    >
      <StyledGoogleButton
        type='default'
        disabled={isFacebookBrowser}
        onClick={() => {
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
            customState: router.asPath,
          })
            .then((success) => {
              console.debug('sucess', success);
            })
            .catch((err) => {
              console.debug('ERROR', err);
            });
        }}
        style={{ borderRadius: 4, borderWidth: 2 }}
      >
        <GoogleLogo />
        <span style={{ paddingLeft: 8 }}>Log in with Google</span>
      </StyledGoogleButton>
    </Tooltip>
  );
};
const SignUpLink = (): ReactElement => {
  const router: NextRouter = useRouter();
  const path = router?.asPath;
  const PublicApplication = path.includes('/apps');
  const applicationId = PublicApplication ? path.split('/')[2] : null;
  return (
    <div
      style={{
        fontSize: 12,
        marginBottom: PublicApplication ? '30px' : '0px',
        marginTop: PublicApplication ? '20px' : '0px',
      }}
    >
      <span style={{ marginTop: 2 }}>Don&apos;t have an account?</span>
      {/* passHreft and legacyBehavior required for styled component link*/}
      <Link
        href={{
          pathname: '/auth/signup',
          query: { redirectTo: path, application_id: applicationId },
        }}
        passHref
        legacyBehavior
      >
        <StyledALink>Sign up here</StyledALink>
      </Link>
    </div>
  );
};

const LoginIndex = (): ReactElement => {
  const [form] = Form.useForm<IFieldValues>();
  const [fieldValues, setFieldValues] = useState<IFieldValues>({});
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const router: NextRouter = useRouter();
  const dispatch = useDispatch();
  const { redirectTo: rawRedirectTo, application_id } = router.query;
  const redirectTo = rawRedirectTo
    ? rawRedirectTo
    : application_id
    ? `/apps/${application_id}/SubmitApplication`
    : null;

  useEffect(() => {
    dispatch(resetStore());
  }, []);
  const PublicApplication = router?.asPath.includes('/apps');
  return (
    <Row
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: !PublicApplication ? 68 : -30,
        marginBottom: 21,
      }}
    >
      {!PublicApplication && <EverysetLogo width={160} />}
      <StyledForm
        form={form}
        name='signIn'
        onFinish={() =>
          signIn(fieldValues, setSignInLoading, router, redirectTo as string)
        }
        layout={'vertical'}
        onValuesChange={(changedValues: IFieldValues) =>
          setFieldValues({ ...fieldValues, ...changedValues })
        }
      >
        <EmailFormItem />
        <PasswordFormItem />
        <ForgotPasswordLink />
        <SignInFormItem signInLoading={signInLoading} />
      </StyledForm>

      <StyledDivider>or</StyledDivider>
      <GoogleButton router={router} />
      <SignUpLink />
    </Row>
  );
};

const StyledForm = styled(Form)`
  margin: 36px 0 0 0;
  max-width: 352px;
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0 16px 0;
  justify-content: center;
  max-width: 352px;
  &.ant-divider::before {
    max-width: 158px;
    border-block-start-color: ${(props) =>
      props.theme?.palette?.greyscale?.[4]};
  }
  span.ant-divider-inner-text {
    font-size: 12px;
    padding: 0px 10px;
    color: ${(props) =>
      props?.theme ? props.theme?.palette?.greyscale?.[5] : 'inherit'};
  }
  &.ant-divider::after {
    max-width: 158px;
    border-block-start-color: ${(props) =>
      props.theme?.palette?.greyscale?.[4]};
  }
`;

const StyledGoogleButton = styled(Button)`
  &.ant-btn {
    justify-content: center;
    align-items: center;
    max-width: 352px;
    width: 352px;
    height: 36px;
    padding: 10px 13px;
    margin: 0 0 16px 0;
    span {
      font-size: 12px;
    }
    color: ${(props) => props.theme?.palette?.greyscale?.[10]};
    background-color: ${(props) => props.theme?.palette?.greyscale?.[0]};
    border-color: ${(props) => props.theme?.palette?.greyscale?.[3]};
  }
`;

// external links -- use <a> tag
const StyledLink = styled.a`
  font-size: 12px;
  color: ${(props) => props.theme?.palette?.greyscale?.[6]};
  :hover {
    color: ${(props) => props.theme.palette.primary[0]};
  }
`;

// internal links -- use <a> tag but embed in <Link/> NextJS component
const StyledALink = styled.a`
  font-weight: 600;
  padding-left: 5px;
  color: ${(props) => props.theme?.palette?.primary?.[0]};
  :hover {
    color: ${(props) => props.theme.palette.primary[0]};
  }
`;

LoginIndex.isPublic = true;

export default LoginIndex;
