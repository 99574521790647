import { ApiNames, ApiProxy, ApiRequest } from '@lib/api/apiProxy';
import {
  EverysetUser,
  UserNotificationPreference,
} from '@lib/types/user.types';

export class UsersQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Users;

  public static getUserNotificationPreferences(
    userId: number
  ): Promise<UserNotificationPreference[]> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/notification_preferences`)
    );
  }

  public static getUser(userId: number): Promise<EverysetUser> {
    return this.apiProxy.execute(new ApiRequest(this.apiName, `/${userId}`));
  }

  public static getUserProfile(userId: number): Promise<UserProfile> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/profile`)
    );
  }

  public static getUserAppearance(userId: number): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/appearance`)
    );
  }
  public static getUserVehicles(userId: number): Promise<vehiclesType> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/vehicles`)
    );
  }
}
