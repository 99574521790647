/* eslint-disable react/no-unknown-property */
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ConfigProvider } from 'antd';
import { OnboardingStateContextProvider } from 'context/onboarding/OnboardingStateContext';
import { SidebarContextProvider } from 'context/SidebarContext';
import type { ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import configureAmplify from '@lib/amplify.lib';
import { AuthProvider } from '@lib/auth';
import { withErrorHandling } from '@lib/errors';
import { store } from '@lib/redux.lib';
import antTheme from '@styles/antTheme';
import theme from '@styles/theme';

import ProtectRoute from './auth/protect';

import '@styles/reset.css';
import '@styles/global.css';
import '@styles/onboardingStyles.css';
import 'antd/dist/reset.css';

configureAmplify();

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  isPublic?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient();

const App = ({ Component, pageProps }: AppPropsWithLayout): ReactElement => {
  const routeProtected = !Component?.isPublic; // "protected" is reserved word and wouldn't track nonexistence
  const getLayout = Component.getLayout || ((page: ReactElement) => page);
  const View = withErrorHandling((props) => <Component {...props} />);
  const Page = getLayout(<View {...pageProps} />);

  return (
    <>
      <Head>
        <title>Jobs | Everyset</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <OnboardingStateContextProvider>
              <AuthProvider>
                <ConfigProvider theme={antTheme}>
                  <ProtectRoute routeProtected={routeProtected}>
                    <SidebarContextProvider>
                      <main>{Page}</main>
                    </SidebarContextProvider>
                  </ProtectRoute>
                </ConfigProvider>
              </AuthProvider>
            </OnboardingStateContextProvider>
          </ThemeProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
