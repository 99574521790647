import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { useOnboardingStateContext } from 'context/onboarding/OnboardingStateContext';
import { useQuery } from 'react-query';

import { UsersQueryApi } from '@lib/api/endpoints';
import {
  setAllUserIds,
  setNotificationPreferences,
  setUserAppearance,
  setUserProfile,
  setUserProfileInfo,
} from '@lib/redux.lib/slices/appSlice';
import { useAppDispatch } from '@lib/redux.lib/store';
import { AuthUserSession } from '@lib/types/authUser.types';
import { isAuthUserAdmin } from '@lib/utils/authHelper';

export const useOnboardingStatus = (
  authUserSession: AuthUserSession,
  logout: () => void
): void => {
  const appDispatch = useAppDispatch();
  const { setOnboardingLoading } = useOnboardingStateContext();
  const router = useRouter();
  // const { userId } = router.query;

  // console.log(router.query.userId, "router.query.ooooo")
  //const onboard = useSelector((state: RootState) => state.app.user.onboardingFirstLoad);

  const [onboardingFirstLoad, setOnboardingFirstLoad] = useState<boolean>(true);
  //console.log(onboardingFirstLoad, "onboardingFirstLoad")
  const allUserIds =
    authUserSession?.signInUserSession.idToken.payload['users'];

  const queryFn = useCallback(async () => {
    if (onboardingFirstLoad) setOnboardingLoading(true);
    try {
      const ruserId = router.query.userId ? router.query.userId : '';
      if (ruserId && ruserId !== '') {
        if (!authUserSession?.attributes)
          authUserSession.attributes = {} as AuthUserSession['attributes'];
        authUserSession.attributes.sub = '';
        authUserSession.attributes['custom:access_code'] = '';
        authUserSession.attributes['custom:castifi_user_id'] = '' + ruserId;
        authUserSession.attributes.email = '';
        authUserSession.attributes.email_verified = true;
      }

      // const userId = router.query.userId ||
      //   authUserSession?.attributes?.['custom:castifi_user_id'] ||
      //   authUserSession?.signInUserSession.idToken.payload[
      //   'custom:castifi_user_id'
      //   ];

      const impersonatedUserId = isAuthUserAdmin(authUserSession)
        ? router.query.impersonate_user_id
        : undefined;
      const userId = impersonatedUserId
        ? impersonatedUserId
        : router.query.userId
        ? router.query.userId
        : authUserSession?.attributes?.['custom:castifi_user_id'] ||
          authUserSession?.signInUserSession.idToken.payload[
            'custom:castifi_user_id'
          ];
      if (!userId) throw 'No user Id';

      appDispatch(setAllUserIds(allUserIds)); // This line will store allUserIds in the Redux store

      const finalUserId = parseInt('' + userId);

      const userData = await UsersQueryApi.getUser(finalUserId);
      if (userData.is_disabled) {
        logout();
        await router.push('/login');
      }
      appDispatch(setUserProfile(userData));
      const UserInfoData = await UsersQueryApi.getUserProfile(finalUserId);
      appDispatch(setUserProfileInfo(UserInfoData));

      const UserAppearance = await UsersQueryApi.getUserAppearance(finalUserId);
      appDispatch(setUserAppearance(UserAppearance));

      const notificationPreferenceList =
        await UsersQueryApi.getUserNotificationPreferences(finalUserId);
      const notificationPreferences = notificationPreferenceList.reduce(
        (acc, { notification_type, opt_in }) => {
          acc[notification_type] = opt_in;
          return acc;
        },
        {} as Record<string, boolean>
      );
      appDispatch(setNotificationPreferences(notificationPreferences));

      setOnboardingFirstLoad(false);
      if (!userData?.bg_onboarded && router.pathname !== '/onboarding') {
        await router.push('/onboarding');
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (authUserSession) setOnboardingLoading(false);
    }
  }, [
    onboardingFirstLoad,
    appDispatch,
    router,
    setOnboardingLoading,
    authUserSession,
  ]);

  useQuery({
    queryKey: [authUserSession, 'userProfile'],
    queryFn,
    refetchOnWindowFocus: false,
  });
};
