import { Typography } from 'antd';
import type { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
const { Text } = Typography;

interface IProps {
  label: ReactNode;
  requiredSymbol?: boolean;
  style?: React.CSSProperties;
}

const Label = ({ requiredSymbol, label, style }: IProps): ReactElement => (
  <StyledText style={style}>
    {label} {requiredSymbol && <span className='required-symbol'>*</span>}
  </StyledText>
);

const StyledText = styled(Text)`
  display: inline-block;
  .required-symbol {
    color: ${(props) => props.theme.palette.color[0]};
  }
`;

export { Label };
