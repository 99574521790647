import { ThemeConfig } from 'antd/es/config-provider/context';

const antTheme: ThemeConfig = {
  token: {
    colorPrimary: '#1733C4',
    colorText: '#202020',
    fontFamily:
      "'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
};

export default antTheme;
