export const BODY_TYPES = [
  'Average',
  'Slim',
  'Athletic / Toned',
  'Muscular',
  'Curvy',
  'Heavyset / Stocky',
  'Plus-Sized / Full-Figured',
  'Little Person',
] as const;
export const HAIR_LENGTH = [
  'Bald',
  'Balding',
  'Short',
  'Medium',
  'Long',
  'Buzz cut',
  'Pixie Cut',
] as const;

export const HAIR_COLOR = [
  'Black',
  'Light Brown',
  'Blonde',
  'Brown',
  'Red',
  'Grey',
  'White',
  'Bald',
  'Other',
] as const;

export const EYE_COLOR = [
  'Blue',
  'Brown',
  'Hazel',
  'Green',
  'Amber',
  'Grey',
] as const;
export const WARDROBE_TEXT = `Please fill out measurements as thoroughly as possible. It is important to also regularly confirm they are up to date. Missing or inaccurate measurements can prevent your profile appearing in searches for roles that you might otherwise match!`;

export const DRESS = [
  '00',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '30',
  '32',
] as const;

export const CUP = [
  'AA',
  'A',
  'B',
  'C',
  'D',
  'DD',
  'DDD',
  'F',
  'G',
  'H',
  'I',
  'J',
] as const;
export const SHOES = [
  '0',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '4.5',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
  '13.5',
  '14',
] as const;

export const SHIRT = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'] as const;

export const ADULT_HAT_SIZE_OPTIONS = [
  '20 3/4',
  '21',
  '21 1/2',
  '22',
  '22 1/2',
  '22 3/4',
  '23',
  '23 1/2',
  '24',
  '24 3/8',
  '24 7/8',
  '25 1/8',
  '26 1/2',
] as const;

export const MINOR_HAT_SIZE_OPTIONS = [
  '19 1/8',
  '19 1/2',
  '19 3/4',
  '20 1/4',
  '20 3/4',
  '21 1/8',
  '21 1/2',
  '21 7/8',
] as const;

export const ALL_HAT_SIZE_OPTIONS = [
  '19 1/8',
  '19 1/2',
  '19 3/4',
  '20 1/4',
  '20 3/4',
  '21',
  '21 1/8',
  '21 1/2',
  '21 7/8',
  '22',
  '22 1/2',
  '22 3/4',
  '23',
  '23 1/2',
  '24',
  '24 3/8',
  '24 7/8',
  '25 1/8',
  '26 1/2',
] as const;

export const MAKE = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'] as const;

export const MODEL = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'] as const;

export const YEAR = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'] as const;

export const COLOR = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'] as const;

export const CONDITION = [
  'Excellent / New',
  'Good / Used',
  'Poor / Visible Damages',
] as const;

export const TYPE = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'] as const;
export const HEIGHT_OPTIONS = [];
for (let feet = 4; feet <= 7; feet++) {
  for (let inches = 0; inches <= 11; inches++) {
    const heightOption = `${feet} ft ${inches} in`;
    HEIGHT_OPTIONS.push(heightOption);
  }
}

export const WEIGHT_OPTIONS = [
  ...Array.from({ length: 261 }, (_, index) => `${index + 40} lbs`),
];

export const CHEST_SIZE_OPTIONS = [
  '30',
  '32',
  '34',
  '36',
  '38',
  '40',
  '44',
] as const;

export const WAIST_SIZE_OPTIONS = [
  '28',
  '30',
  '32',
  '34',
  '36',
  '38',
  '40',
  '44',
  '46',
  '48',
] as const;

export const Reasons = [
  'Sick/Injured',
  'Unexpected matching recall on another project',
  'Transportation Issue',
  'Terms of the jobs are different than originally stated',
  'Family emergency',
  'Audition',
  'Double booked',
  'Other',
];

export const wardrobeOptionsMap = {
  dress: DRESS,
  cup: CUP,
  shoe_size: SHOES,
  shirt_size: SHIRT,
  chest: CHEST_SIZE_OPTIONS,
  waist: WAIST_SIZE_OPTIONS,
};

export const getWardrobeOptions = (
  wardrobeType: string,
  isMinor = false
): readonly string[] => {
  if (wardrobeType === 'hat') {
    return isMinor ? MINOR_HAT_SIZE_OPTIONS : ADULT_HAT_SIZE_OPTIONS;
  }
  return wardrobeOptionsMap[wardrobeType];
};

export const MinMaxValues = {
  hip: { min: 30, max: 56 }, // Example min and max values for Hat
  waist: { min: 20, max: 56 },
  chest_bust: { min: 30, max: 56 },
  bust: { min: 30, max: 46 },
  neck: { min: 13, max: 19 },
  sleeve: { min: 32, max: 36 },
  inseam: { min: 30, max: 38 },
  pants: { min: 0, max: 32 },
};

export const WardrobeLabel = {
  hat: 'Hat Size',
  shirt_size: 'Shirt Size',
  shoe_size: 'Shoe Size',
  waist: 'Waist Size',
  cup: 'Cup Size',
  bust: 'Bust Size',
  dress: 'Dress Size',
  hip: 'Hip Size',
  pants: 'Pant Size',
  chest_bust: 'Chest Size',
  inseam: 'Inseam Size',
  neck: 'Neck Size',
  sleeve: 'Sleeve Size',
};

export const WardrobeMapping = {
  Hat: 'hat',
  Shirt: 'shirt_size',
  Shoe: 'shoe_size',
  Waist: 'waist',
  'Cup (Women)': 'cup',
  'Bust (Women)': 'bust',
  'Dress (Women)': 'dress',
  'Hips (Women)': 'hip',
  'Pant (Women)': 'pants',
  'Coat/Chest (Men)': 'chest_bust',
  'Inseam (Men)': 'inseam',
  'Neck (Men)': 'neck',
  'Sleeve (Men)': 'sleeve',
};

export const WARDROBE_LABELS_BY_GENDER = {
  Male: [
    'Hat',
    'Shirt',
    'Shoe',
    'Waist',
    'Coat/Chest (Men)',
    'Inseam (Men)',
    'Neck (Men)',
    'Sleeve (Men)',
  ],
  Female: [
    'Hat',
    'Shirt',
    'Shoe',
    'Waist',
    'Cup (Women)',
    'Bust (Women)',
    'Dress (Women)',
    'Hips (Women)',
    'Pant (Women)',
  ],
  Other: [
    'Hat',
    'Shirt',
    'Shoe',
    'Waist',
    'Cup (Women)',
    'Bust (Women)',
    'Dress (Women)',
    'Hips (Women)',
    'Pant (Women)',
    'Coat/Chest (Men)',
    'Inseam (Men)',
    'Neck (Men)',
    'Sleeve (Men)',
  ],
  'Non-Binary': [
    'Hat',
    'Shirt',
    'Shoe',
    'Waist',
    'Cup (Women)',
    'Bust (Women)',
    'Dress (Women)',
    'Hips (Women)',
    'Pant (Women)',
    'Coat/Chest (Men)',
    'Inseam (Men)',
    'Neck (Men)',
  ], // excludes Sleeve (Men)
};
