import { useCallback, useEffect, useState } from 'react';
import router from 'next/router';
import { message } from 'antd';
import { Auth, Hub } from 'aws-amplify';
import { useOnboardingStateContext } from 'context/onboarding/OnboardingStateContext';
import type { Dispatch, SetStateAction } from 'react';

import { HubCapsule } from '@aws-amplify/core';
import { UsersQueryApi } from '@lib/api/endpoints';
import { AuthUserSession } from '@lib/types/authUser.types';

import { googleSignout } from './googleSignout';
import { AuthListener } from './listener';

const setupSession = (
  setAuthUserSession: (session: AuthUserSession) => void,
  setOnboardingLoading: (value: boolean) => void,
  setSessionLoading: (value: boolean) => void,
  routerPath?: string
) => {
  return Auth.currentAuthenticatedUser()
    .then(async (sessionData) => {
      const userId =
        sessionData.attributes?.['custom:castifi_user_id'] ||
        sessionData.signInUserSession.idToken.payload['custom:castifi_user_id'];
      const userData = await UsersQueryApi.getUser(parseInt(userId));
      setOnboardingLoading(true);
      if (routerPath) {
        await router.push(routerPath);
        setOnboardingLoading(false);
        setSessionLoading(false);
        setAuthUserSession(sessionData);
      }
      if (userData.bg_onboarded === false) {
        const { asPath } = router ?? {};
        await router?.push({
          pathname: '/onboarding',
          ...(asPath.includes('/apps/')
            ? { query: { redirectTo: asPath } }
            : null),
        });
      }
      setAuthUserSession(sessionData);
    })
    .catch((error) => {
      console.error(error);
      setOnboardingLoading(false);
    })
    .finally(() => {
      setSessionLoading(false);
    });
};

const takedownSession = async (
  setAuthUserSession: Dispatch<SetStateAction<AuthUserSession>>
) => {
  googleSignout();
  await (Auth as any)._storage.clear();
  await (Auth as any).cleanCachedItems();
  return setAuthUserSession(null);
};

type UseSessionObject = {
  authUserSession: AuthUserSession;
  sessionLoading: boolean;
  setSessionLoading: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
};

export const useSession = (): UseSessionObject => {
  const [sessionLoading, setSessionLoading] = useState<boolean>(true);
  const [authUserSession, setAuthUserSession] = useState<AuthUserSession>(null);
  const { setOnboardingLoading } = useOnboardingStateContext();

  useEffect(() => {
    // initialize listener only once in constructor
    const result = Hub.listen('auth', (data: HubCapsule) =>
      AuthListener(
        data,
        (routerPath: string) =>
          setupSession(
            setAuthUserSession,
            setOnboardingLoading,
            setSessionLoading,
            routerPath
          ),
        setSessionLoading,
        () => takedownSession(setAuthUserSession)
      )
    );

    // check for existing session on mount
    setupSession(setAuthUserSession, setOnboardingLoading, setSessionLoading);

    // remove listener on takedown - just return listener result that has a remove callback
    return result;
  }, []);

  const logout = useCallback(() => {
    setSessionLoading(true);
    // TODO
    // clear redux user_id as well
    console.log(sessionLoading, 'sessionLoading');
    Auth.signOut()
      .then((success) => {
        message.info('successfully signed out', success);

        setAuthUserSession(null);
        setSessionLoading(false);

        window.location.reload();
        router.push('/');
      })
      .catch((err) => {
        console.log('ERROR', err);
        message.error('Error');
        setSessionLoading(false);
      });
  }, [setAuthUserSession, setSessionLoading]);

  return { authUserSession, sessionLoading, setSessionLoading, logout };
};
