const theme = {
  palette: {
    primary: [
      '#1733C4', // 0: Default
      '#00BFD2', // 1: Darken 4%
      '#00BCCF', // 2: Darken 5%
      'rgba(0, 201, 220, 0.2)', // 3: Fade 20%
      '#3A51CD', // 4: Lighten 3%
      '#26EFFF', // 5: Lighten 15%
      '#1FE8FB', // 6: Lighten 12%
      '#00C4D7', // 7: Darken 2%
      'rgb(0, 128, 140)', // 8: Algolia color
      '#14DDF0', // 9: Lighten 8%
      '#00B5C8', // 10: Darken 8%,
      '#00AABD', // 11: Darken 12%
      '#0DD6E9', // 12: Lighten 5%
      '#1733c466', // 13 40% opacity
      '#1733c41a', // 14 10% opacity
    ],
    secondary: [
      '#2d3446', // 0: DarkBlue
      '#f1f3f6', // 1: LightBluish
      '#788195', // 2: LightBlue
      '#E4E6E9', // 3: LightBluish Darken 5%
      '#202739', // 5: DarkBlue Darken 5%
      '#f5f6f8', // 6: LighterBluish
      '#e9ebf1', // 7: DarkBluish
      '#F6F8FB', // 8: LighterBluish Lighten 2%
      '#E9EBEE', // 9: LighterBluish Darken 3%
      '#1a1a1a', // 10: Sidebar submenu select
    ],
    color: [
      '#E14A4A', // 0: Red
      '#F89020', // 1: Orange
      '#14984C', // 2: Green
      '#7ED321', // 3: LimeGreen
      '#39435f', // 4: BlueShade
      '#FFCA28', // 5: Yellow
      '#F2BD1B', // 6: Yellow Darken 5%
      '#3b5998', // 7: Facebook
      '#344e86', // 8: Facebook Darken 5%
      '#dd4b39', // 9: Google Plus
      '#d73925', // 10: Google Plus Darken 5%
      '#e14615', // 11: Auth0
      '#ca3f13', // 12: Auth0
      '#e0364c', // 13: themeColor--AlizarinCrimson
    ],
    shadow: [
      '1px 1px 7px 1px rgba(64, 64, 64, 0.25)', // 0: 1,1,7,1 25%
      '1px 1px 7px 1px rgb(23 51 196 / 35%)', // 1: 1,1,1,7 35%
      '0 0 0 2px rgba(24, 144, 255, 0.2)', // 2: ant designs default boxshadow for inputs
    ],
    fill: [
      '#FBDBDB', // 0: Red
      '#FDE3CA', // 1: Orange
      '#DBEBDB', // 2: Green,
      'rgba(255, 237, 237)', // 3: Light Red
    ],
    hover: ['#3A51CD'],
    active: ['#1733C4'],
    highlight: [
      '#E6E9F7', // 0: Highlight
      'rgba(23, 51, 196, 0.1)', // 1: Highlight
    ],
    warning: [
      '#F89020', // 0: Warning
    ],
    success: [
      '#14984C', // 0: Success
    ],
    error: [
      '#E14A4A', // 0: Error
    ],
    greyscale: [
      '#FDFDFD', // 0: White
      '#F9F9F9', // 1: Grey 1
      '#ECECEC', // 2: Grey 2
      '#D8D8D8', // 3: Grey 3
      '#BABABA', // 4: Grey 4
      '#868686', // 5: Grey 5
      '#494949', // 6: Grey 6
      '#202020', // 7: Black, Text
    ],
    text: [
      '#202020', // 0: Text
      '#323332', // 1: Heading
      '#595959', // 2: HeadingLight
      '#979797', // 3: Text
      '#797979', // 4: TextDark
      '#6a6c6a', // 5: Heading Lighten 22%
      'rgb(68, 68, 68)', // 6: Castifi Medium
      'rgb(200, 200, 200)', // 7: Castifi Medium Light
    ],
    border: [
      '#e9e9e9', // 0: Border
      '#d8d8d8', // 1: BorderDark
      '#ebebeb', // 2: BorderLight
      '#d3d3d3', // 3:
      'rgba(228, 228, 228, 0.65)', // 4:
    ],

    textLineHeight: ['30px', '24px', '21px', '18px', '17px', '15px'],
    textFontSize: [
      '25px', // 0
      '20px', // 1
      '17px', // 2
      '16px', // 3
      '15px', // 4
      '14px', // 5
      '13px', // 6
      '12px', // 7
    ],
    textFontWeight: ['600', '400'],
  },
  fonts: {
    primary: 'Inter Sans, Roboto, sans-serif',
    pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  },
};

export type ThemeConfig = typeof theme;
export default theme;
